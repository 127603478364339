body {
  &.bg-PrimaryBg {
    background-color: #fafafa; 
  }
  .theme {
    * {
      font-family:  'Open Sans', sans-serif;
    }
    &.theme-fintech {
      --dropdown-hover-color: rgba(13,134,255, 0.08);
      --light-gray: #F8FAFD;
      --medium-gray: #f9f9f9;  
      --menu-bg: #ffffff;
      --PrimaryBg: #ffffff;
      --secondaryBg: #0d86ff;
      --tabBg: #ffffff;
      --layoutBg: #ffffff;
      // --tableStripe: #f0f0f0;
      --tableStripe: #ffffff;
      --tableStripeHover: rgba(13,134,255, 0.08);
      --navItemsText: #12161E;
      --orderFormPlaceholderColor: #979ba1;
      /* UI work start */
      --bidColor: #3cbd31;
      --askColor: #e55235;
      --bidColorRGB: 60, 189, 49;
      --askColorRGB: 229, 82, 53;
      --borderColor: #e9ebee;
      --borderColor2: #e9ebee;
      --selectHover: rgba(13,134,255, 0.08);
      --formLable: #979ba1;
      --color-white: #ffffff;
      --color-black: #000000;
      --color-black-rgb: 0, 0, 0;
      --hover-color: rgba(13,134,255, 0.08);
      --loader-primary: #0d86ff;
      --loading-rgb: 255, 255, 255;
    


      --PrimaryBgDark: #f9fafb;
      --secondaryBgDark: #000000;
      --tabBgDark: #f0f1f3;
      --layoutBgDark: #ffffff;
      --tableStripeDark: #f3f6fa;
      --navItemsTextDark: #ffffff;
      --primaryText: #0d86ff;
      --secondaryText: #0d86ff;
    
      // new var
      --menu-color: #12161E;
      --menu-active-color: #0d86ff;
      --primary-color: #0d86ff;
      --tabItemText: #636872;
      --tableTextColor: #636872;
      --checkbox-color: #0d86ff;
      --arrowColor: #ffffff;
      --porfolio-text-color: #636872;
      --chart-color: #0d86ff;
      --menu-font-color: #ffffff;
      --404-color: #636872; 
      
    
      // New Class
      --tab-color:#12161E;
      --hover-color:rgb(13,134,255, 0.08);
      --dark-color:#12161E;
      --brand-primary-rgb: rgba(13,134,255, 0.23);
      --brand-primary-rgb2: 13,134,255;
      --label-color:#979ba1;
      --brand-info:#f4f4f4;
      --border-color-new: #e9ebee;
      --black-rgb:0, 0, 0;
      --border-color:#e9ebee;
      --table-text:#636872;
      --table-header-bg: #F8FAFD;
      --gray-color: #636872;
      --table-border:#f3f6fa;
      --button-color:#636872;
      .logo {
        height: 48px;
        img {
          height: 100%;
        }
      }
      .menu-bg {
        box-shadow: 0px 2px 5px 0px rgba(43, 50, 64, 0.12);
      }     
      .header-main {
        .header-search {
          .header-search-box {
            input {
              border-color: var(--border-color) !important;
              background-color: transparent !important;
              color: var(--dark-color);
              &:focus {
                border:1px solid rgba(var(--brand-primary-rgb2), 1) !important;  
                outline: 0;
                box-shadow: 0 0 6px 2px rgba(var(--brand-primary-rgb2), 0.15) !important;
                background-color: var(--color-white) !important;
              }
            }
            button {
              border: 1px solid var(--border-color) !important;
              background-color: var(--table-header-bg) !important;
              svg {
                  path {
                    fill: var(--dark-color) !important;
                  }
                }
              &:hover {
                background-color: var(--button-color) !important;
                border: 1px solid var(--button-color) !important;
                svg {
                  path {
                    fill: var(--color-white) !important;
                  }
                }
              }
            }
          }
        }
        .header-left {
          img {
            height: 48px !important;
          }
        }
      }
      .current-design-btn {
        .upload-group {
          .upload-submit {
            box-shadow: none !important;
            -webkit-box-shadow: none !important;
          }
        }
        .new-btn-2 {
          background-color: var(--color-white) !important;
          border: solid 1px var(--button-color) !important;
          &:before {
            background-color: var(--button-color) !important;
          }
          span {
            svg {
              .innerColor  {
                fill: var(--button-color) !important;
              }
              #innerColorStro {
                stroke: var(--button-color) !important;
              }
            }
          }
          &:hover {
            border: solid 1px transparent !important;
            span {
              svg {
                .innerColor  {
                  fill: var(--color-white) !important;;
                }
                #innerColorStro {
                  stroke: var(--color-white) !important;
                }
              }
            }
          }
        }
      }
      .tab-main {
        > ul {
          border-radius: 0;
          background-color: transparent;
          border-bottom: 1px solid var(--border-color);
        }
      }
      .tab-main {
        > ul {
         > li {
            position: relative;
            > span, > a {
              color: var(--gray-color) !important;
              font-weight: 600;
            }
            &.active, &:hover {
              > span,> a {
                color: var(--dark-color) !important;
                background-color: transparent;
                &:before {
                  width: 28px !important;
                  left: 50%;
                  width: 0;
                  bottom: 0;
                  height: 3px;
                  content: "";
                  position: absolute;
                  transform: translateX(-50%);
                  background: var(--primary-color);
                  transition: width 0.3s ease;
                  border-radius: 1.5px;
                }
              }
            }
            
          }
        }
        .main-menu-group {
          li {
            .submenu {
              > li {
                a {
                  color: var(--gray-color) !important;
                  &:hover {
                    background-color: var(--hover-color) !important;
                  }
                }
              }
            }
          }
        }
      }
      .grid-item {
        background: transparent;
        border-radius: 5px;
        border: 1px solid var(--border-color) !important;
        .grid-item__action {
          padding-right: 10px;
        }
        .grid-item__graph {
          padding: 0;
        }
      }
      .userwatchlist-panel {
        .userwatchlist-list {
          .userwatchlist-item {
            .item-name {
              .i-name {
                font-weight: 600;
                color: var(--dark-color);
              }
            }
            .i-sub-txt {
              color: var(--gray-color);
              font-weight: 500;
            }
          }
        }
      }
      .css-1yhyykx-singleValue {
        color: var(--dark-color);
      }
      .round-plus {
        color: rgba(var(--brand-primary-rgb2),0.3);
        border: none;
        padding: 0;
        &:hover {
          color: rgba(var(--brand-primary-rgb2),1);
          svg {
            .innerColor {
              fill:rgba(var(--brand-primary-rgb2),1);
            }
          }
        }
        &.mr-2 {
          margin-right: 0 !important;
        }
        svg {
          .innerColor {
            fill:#A9A9A9;
          }
        }
      }
      .grid-remove-btn {
        margin: 0;
        margin-left: 8px;
        color: #A9A9A9;
        &:hover {
          color: var(--askColor);
          svg {
            path {
              fill:var(--askColor);
            }
          }
        }
        svg {
          width: 18px;
          height: 18px;
          path {
            fill:#A9A9A9;
          }
        }
      }
      .headerCell .table-title-name {
        color: var(--gray-color);
      }
      .order-form-group {
        .order-form-search {
              color: var(--dark-color);
              border-radius: 5px;
              &:focus {
                border:1px solid rgba(var(--brand-primary-rgb2), 1) !important;  
                outline: 0;
                box-shadow: 0 0 6px 2px rgba(var(--brand-primary-rgb2), 0.15) !important;
                background-color: var(--color-white) !important;
              }
        }
        .order-item-name {
          h3 {
            font-weight: 600;
          }
        }
       .order-form-body {
        .bid-ask-group {
          .bid-ask-box {
            span {
              color: var(--dark-color);
            }
          }
        }
        .bid-ask-group {
          .bid-ask-box {
            border-radius: 5px;
          }
        }
        .order-form-main {
          .input-container {
            input {
              border-radius: 5px 
            }
          }
          .order-form-select-input {
            .css-8myb9u-control {
              border-radius: 5px !important;
              &:focus {
                border:1px solid rgba(var(--brand-primary-rgb2), 1) !important;  
                outline: 0;
                box-shadow: 0 0 6px 2px rgba(var(--brand-primary-rgb2), 0.15) !important;
                background-color: var(--color-white) !important;
              }
            }
          }
        }
       }      
      }
      .tableCell {
        padding: 12px 8px;
        border-bottom: 1px solid var(--table-border);       
      }
      .userwatchlist-panel {
        .userwatchlist-list {
          .userwatchlist-item-header {
            background-color: var(--table-header-bg) !important;
            // border-bottom: 1px solid var(--border-color);
          }
          .userwatchlist-item {
            &.bg-tableStripeBg {              
              border-bottom: 1px solid var(--border-color);
              border-top: 1px solid var(--border-color);
            }
          }
        }
      }
      .tbScroll {
        table {
          thead {
            tr {
              th {
                background-color: var(--table-header-bg) !important;
                border-bottom: 1px solid var(--table-border); 
              }
            }
          }
        }
      }
      .input-container, .input-container-left {
        input {
          &:focus {
            border:1px solid rgba(var(--brand-primary-rgb2), 1) !important;  
            outline: 0;
            box-shadow: 0 0 6px 2px rgba(var(--brand-primary-rgb2), 0.15) !important;
            background-color: var(--color-white) !important;
          }
        }
      }
      .new-header-right {
        .download-link {
          border-radius: 5px !important;
          color: var(--dark-color);
          &:hover {
            color: var(--dark-color) !important;
          }
          svg {
            path {
              fill: var(--dark-color);
            }
            .innerColor {
              stroke: var(--dark-color);
            }
          }
        }
        .dropdown-menu  {
          border-radius: 5px !important;
        }
        .upload-group {
          border-radius: 5px !important;
          .input-container {
            input {
              background-color: var(--light-gray);
              color: var(--dark-color);

            }
          }
          .file {
            svg {
              &.uploadFileLbl {
                .innerColorSto, #innerColorSto {
                  stroke: var(--primary-color) !important;
                } 
              }
            }
          }
        }
        .download-link {
          svg {
            #innerColorStro, .innerColorStro {
              stroke: var(--dark-color) !important;
            }
          }
        }
      }
      .notification-main {
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25) !important;
        border: 1px solid var(--border-color);
      }
      .ms-options-wrap {
        > .ms-options {
          border-radius: 5px !important;
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25) !important;
          border: 1px solid var(--border-color);
          .ms-search {
            border: 1px solid var(--border-color) !important;
            background-color: var(--table-header-bg) !important;
            img {
              background-color: transparent !important;
            }
            input {
              background-color: transparent !important;
              color: var(--dark-color);
            }
          }
        }
      }
      #portfolio-selection {
        label {
          &:hover {
            background-color: var(--hover-color);
          }
        }
      }
      .profil-setting-box {
        .form-main {
          .form-group {
            &.custom-input {
              .input-container {
                input {
                  color: var(--dark-color);
                  &:focus {
                    border:1px solid rgba(var(--brand-primary-rgb2), 1) !important;  
                    outline: 0;
                    box-shadow: 0 0 6px 2px rgba(var(--brand-primary-rgb2), 0.15) !important;
                    background-color: var(--color-white) !important;
                  }
                }
              }
            }
            &.custome-select {            
              .css-mxwivf-control, .css-19tuq7o-control {
                background-color: var(--light-gray) !important;
                border-color: var(--light-gray) !important;
                &:focus {
                  border:1px solid rgba(var(--brand-primary-rgb2), 1) !important;  
                  outline: 0;
                  box-shadow: 0 0 6px 2px rgba(var(--brand-primary-rgb2), 0.15) !important;
                  background-color: var(--color-white) !important;
                }
                .css-m33haa-singleValue {
                  color: var(--dark-color);
                }
                .css-1xfdk2s-indicatorContainer, .css-rux3gz-indicatorContainer {
                  color: var(--dark-color);
                }
              }
            }
          }
        }
      }
      .switch-container {
        .opt-txt {
          color: var(--dark-color);
          font-size: 14px;
          font-weight: 500;
        }
        .switch-slider {
          background-color: var(--hover-color);
          height: 25px;
          width: 55px;
          &:before {
            background-color: var(--primary-color);
            height: 22px;
            width: 22px;
          }
        }
      }
      .multi-option-tab {
        li {
          a {
            color: var(--gray-color);
          }
          &.active, &:hover {
            a {
              color: var(--dark-color) !important;
            }
          }
        }
      }
      .tab-group {
        .current-design-btn {
          .dropdown-menu {
            border-radius: 5px !important;
            .grid-layout-panel-listitem {
              a {
                color: var(--dark-color);
                svg {
                  path {
                    fill:var(--dark-color);
                  }
                }
              }
            }
          }
        }
      }
      .c-tooltip-wrap {
        .c-tooltip {
          border-radius: 5px !important;
        }
      }   
      .c-tooltip-wrap .c-tooltip li:first-child {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }  
      .c-tooltip-wrap .c-tooltip li:last-child {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }   
      .watchlist-panel {
        .watchlist-panel-header {
         p {
          color: var(--dark-color);
          font-weight: 600;
         }
        }
      }
      .watch-list-searchbar .order-form-search {
        color: var(--dark-color); 
        &:focus {
          border:1px solid rgba(var(--brand-primary-rgb2), 1) !important;  
          outline: 0;
          box-shadow: 0 0 6px 2px rgba(var(--brand-primary-rgb2), 0.15) !important;
          background-color: var(--color-white) !important;
        }
      }
      .grid-item-header-title {
        font-size: 15px;
        @media (max-width: 1599px) {
          font-size: 13px;
        }
      }
      .round-plus, .grid-remove-btn{
        position: relative;
        .tooltiptext {
          display: none;
          text-align: left;
          padding: 0;
          position: absolute;
          z-index: 1;
          left: 38%;
          transform: translateX(-50%);
          right: inherit;
          top: 25px;
          min-width: auto;
          max-width: inherit;
          border-radius: 5px !important;
          background-color: var(--color-white);
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25) !important;
          border: 1px solid var(--border-color);
          z-index: 9999;
          p {
            margin: 0;
            padding: 5px 10px;
            text-align: left;
            font-size: 14px;
            line-height: 20px;
            color: var(--dark-color);
            font-weight: 500;
            margin-bottom: 0;
            white-space: nowrap;
          }
          &:after {
            position: absolute;
            top: -7px;
            left: 50%;
            transform: translateX(-50%);
            right: inherit;
            text-align: center;
            display: inline-block !important;
            border-right: 7px solid transparent;
            border-bottom: 7px solid #f0f1f3;
            border-left: 7px solid transparent;
            content: "";
          }
          &:before {
            position: absolute;
            top: -8px;
            left: 50%;
            transform: translateX(-50%);
            right: inherit;
            display: inline-block !important;
            border-right: none;
            border-bottom: 8px solid #f0f1f3;
            border-left: none;
            content: "";
          }
          &.tool-right {
            left: inherit;
            transform: inherit;
            right: 0;
            &:after {
              left: inherit;
              transform: inherit;
              right: 5px;
            }
            &:before {
              left: inherit;
              transform: inherit;
              right: 5px;
            }
          }
        }
        &:hover {
          .tooltiptext {
            display: block;
          }
        }
      }
      .grid-item__action {
        .kill-btn {
          background-color: var(--askColor)var(--color-white);
          border: 1px solid var(--askColor);
          color: var(--askColor);
          margin: 0 !important;
          &:hover {
            background-color: var(--askColor);
            border: 1px solid var(--askColor);
            color: var(--color-white);
          }
        }
      }
      .custome-modal {
        .modal-body {
          .modal-header {
            h2 {
              color: var(--dark-color);
            }
          }
          .allocates_ul {
            li {
              .text-value {
                color: var(--dark-color);
              }
            }
          }
        }
      }
      .text-secondaryBg {
        color: var(--dark-color);
      }
      .grid-item__header {
        border-radius: 5px 5px 0 0;
      }
      .drower-box {
        border-top-left-radius: 5px !important;
          border-bottom-left-radius: 5px !important;
      }
      .rounded-l-\[20px\] {
        border-top-left-radius: 5px !important;
          border-bottom-left-radius: 5px !important;
      }
      
    }
    &.theme-emfi {
      * {
         font-family:  "Inter", sans-serif; 
      }
    }
  }
} 