@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
/* Global Class */
* {
  font-family: "Inter", sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.input-container {
  position: relative;
  display: flex;
  flex-direction: column;
  .filled {
    transform: translate(0, 4px) scale(0.8);
  }
  label {
    position: absolute;
    pointer-events: none;
    transform: translate(0, 12px) scale(1);
    transform-origin: top left;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    color: var(--formLable);
    font-size: 12px;
    line-height: 1;
    /* right: 8px; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 10px);
    text-align: left;
    &.right-2 {
      padding-left: 8px;
      text-align: right !important;
    }
  }
  input {
    height: 35px;
    /*border-radius:4px*/
    /* border: 1px solid var(--borderColor2); */
    padding: 12px 8px 4px 8px;
    font-size: 12px;
    line-height: 1;
    outline: none;
    box-shadow: none;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    width: 100%;
  }
}

.input-container {
  &:focus-within {
    label {
      transform: translate(0, 4px) scale(0.8);
      color: #909090;
    }
  }
  &.input-container-right {
    label {
      transform-origin: top right;
    }
  }
}

.input-container-date {
  input {
    height: 35px;
    /*border-radius:4px*/
    /* border: 1px solid var(--borderColor2); */
    padding: 12px 8px 4px 8px;
    font-size: 12px;
    line-height: 1;
    outline: none;
    box-shadow: none;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    width: 100%;
  }
}
datepick {
  @apply w-full border border-borderColor focus:ring-0 animate-none focus:border-borderColor;
}

.nav {
  @apply flex justify-between items-center text-navItemsTextDark;
  height: 70px;
  padding: 0 15px;
}

.navItem {
  @apply text-navItemsText font-Inter text-base cursor-pointer mx-4;
}

.tabNav {
  @apply flex justify-between items-center;
  border-radius: 20px 20px 0 0;
}

.tableCell {
  @apply px-2  py-2 text-xs;
}

.hoverTableCell {
  &:hover {
    background-color: #b0ccf3;
    // opacity: 0.3;
  }
}
.hideScroll::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey; */
  border-radius: 10px;
  /* margin-top: 4.5rem; */
  margin-bottom: 1rem !important;
  background-color: #ebebeb;
}
.tbScroll::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey; */
  border-radius: 10px;
  margin-top: 4.5rem;
  margin-bottom: 1rem !important;
  background-color: #ebebeb;
}
.tbScroll,
.order-form-group,
.userwatchlist-list,
.grid-item__graph .highcharts-container {
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
  }
  &:hover {
    &::-webkit-scrollbar-track {
      background-color: #ebebeb;
    }
    &::-webkit-scrollbar-thumb {
      background: #5f5f5f;
    }
  }
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #5f5f5f;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /* background: #ff0000; */
}

/* Header CSS */
.marginright-10 {
  margin-right: 10px !important;
}
.header-main {
  .header-left,
  .header-right,
  .header-center {
    min-width: 33.33%;
  }
  .header-left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    img {
      height: 53px !important;
    }
    // .breadcrumb {
    //   margin: 0 0 0 20px;
    //   padding: 0 0 0;
    //   white-space: nowrap;
    //   overflow: hidden;
    //   text-overflow: ellipsis;
    //   max-width: calc(100% - 150px);
    //   list-style: none;
    //   display: flex;
    // align-items: center;
    // justify-content: flex-start;
    // }
    .breadcrumb {
      margin: 0 0 0 20px;
      padding: 0 0 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: calc(100% - 150px);
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      @media (max-width: 1499px) {
        margin: 0 0 0 10px;
      }
      > li {
        font-size: 14px;
        @media (max-width: 1499px) {
          font-size: 13px;
        }
        > a {
          color: var(--menu-color);
          font-size: 14px;
          text-decoration: none !important;
          font-weight: 500;
          &:hover {
            color: var(--menu-active-color);
            text-decoration: none !important;
          }
          @media (max-width: 1499px) {
            font-size: 13px;
          }
        }
        + {
          li {
            display: flex;
            align-items: center;
            justify-content: center;
            &:before {
              // padding: 0 4px 0 2px;
              // color: var(--menu-color);
              // display: inline-block;
              // font-size: 14px;
              // font-weight: 500;
              // @media (max-width: 1499px) {
              //     font-size: 13px;
              //     padding: 0 2.5px 0 0.5px;
              // }
              content: "";
              margin-top: 2px;
              padding: 4px 0 2px 0;
              position: relative;
              width: 15px;
              height: 11px;
              background-color: transparent;
              background: url("../src/assets/down-arrow-btn.svg") no-repeat center center / 11px;
              display: flex;
              align-items: center;
              justify-content: center;
              transform: rotate(-90deg);
              @media (max-width: 1499px) {
                padding: 2.5px 0 0.5px 0;
              }
            }
          }
        }
      }
      > .active {
        color: var(--menu-active-color);
      }
    }
  }
  .header-search {
    .header-search-box {
      input {
        padding: 5px 8px;
        background-color: var(--light-gray);
        border: solid 1px #99999c;
        border-radius: 10px 0 0 10px !important;
        height: 35px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 382px;
        font-size: 14px;
        color: var(--primary-color);
        &:focus {
          outline: none;
        }
        &::-webkit-input-placeholder {
          color: #999999;
        }

        &:-ms-input-placeholder {
          color: #999999;
        }

        &::placeholder {
          color: #999999;
        }
      }
      button {
        min-height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0 10px 10px 0 !important;
        background-color: #dfdbdb;
        color: var(--primary-color) !important;
        border: 0 !important;
        border-left: 0 !important;
        position: relative;
        margin-left: 0 !important;
        padding: 6px 12px;
        &:hover {
          background-color: var(--color-white);
        }
        svg {
          width: 14px;
          height: 14px;
          path {
            fill: var(--primary-color) !important;
          }
        }
      }
    }
  }
  .header-right {
    .currency-dropdown-design {
      border-radius: 0px !important;
      background-color: var(--color-white);
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25) !important;
      border: 1px solid #e5e5e5;
      min-width: auto;
      li {
        justify-content: flex-start;
        display: flex;
        align-items: center;
        padding: 8px 16px;
        border-radius: 0px !important;
        background-color: var(--color-white) !important;
        color: var(--primary-color) !important;
        font-size: 14px;
        line-height: 18px;
        white-space: nowrap;
        cursor: pointer;
        &:hover,
        &.active {
          border-radius: 0px !important;
          background-color: #f2f2f2 !important;
        }
      }
    }
  }
}

// tabs design
.tab-main {
  // padding: 15px 15px 0;
  // padding-top: 20px;
  > ul {
    border-radius: 20px 20px 0 0;
    background-color: #f0f1f3;
    border-bottom: 1px solid var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-height: 50px;

    li {
      span,
      a {
        border-radius: 20px 20px 0 0;
        cursor: pointer;
        color: #968a8a;
        font-size: 14px;
        font-weight: 400;
        min-height: 49px;
        display: flex;
        align-items: center;
        border-color: transparent;
        padding: 10px 16px;
        cursor: pointer;
        &:hover,
        &:focus {
          color: var(--tab-color);
          background-color: var(--hover-color);
        }
      }
      &.active {
        span,
        a {
          color: var(--color-white);
          background-color: var(--primary-color);
        }
      }
      .dropdown-menu {
        position: absolute;
        border-radius: 0px !important;
        background-color: var(--color-white);
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25) !important;
        border: 1px solid #e5e5e5;
        min-width: 190px;
        z-index: 99;
        max-height: 85vh;
        // overflow: auto;
        margin-top: 1px;
        li {
          a,
          Link {
            justify-content: flex-start;
            display: flex;
            align-items: center;
            padding: 8px 16px;
            border-radius: 0px !important;
            background-color: var(--color-white) !important;
            color: var(--primary-color) !important;
            font-size: 14px;
            line-height: 18px;
            white-space: nowrap;
            cursor: pointer;
            font-weight: 300;
            min-height: auto;
            &:hover,
            &.active {
              border-radius: 0px !important;
              background-color: #f2f2f2 !important;
            }
          }
          &.active {
            a {
              color: var(--color-white) !important;
              background-color: var(--primary-color) !important;
            }
          }
        }
      }
    }
  }
  .main-menu-group {
    li {
      .submenu {
        display: none;
        > li {
          position: relative;
          a {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            .submenu-arrow {
              transform: rotate(-90deg);
              width: 8px;
              height: auto;
              position: absolute;
              right: 7px;
              height: auto;
              font-weight: 300;
            }
          }
          .sub-sub-manu {
            display: none;
          }
          &:hover {
            .sub-sub-manu {
              display: block;
              top: 0;
              left: 100%;
              margin-top: 0;
              margin-left: -1px;
              border-radius: 0;
            }
          }
        }
      }
      &:hover {
        .submenu {
          display: block;
        }
      }
      span {
        &:hover {
          .submenu {
            display: block;
          }
        }
      }
    }
  }
}
.currency-selection {
  .currencyIconMain {
    width: auto !important;
    height: 18px;
    &#BASEIcon {
      height: 24px;
    }
    .currency-icon {
      fill: var(--color-white);
    }
  }
  .tooltiptext {
    display: none;
    text-align: left;
    padding: 0;
    position: absolute;
    z-index: 1;
    left: 38%;
    transform: translateX(-50%);
    right: inherit;
    top: 38px;
    min-width: auto;
    max-width: inherit;
    border-radius: 5px !important;
    background-color: var(--color-white);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25) !important;
    border: 1px solid var(--border-color);
    z-index: 9999;
    p {
      margin: 0;
      padding: 5px 10px;
      text-align: left;
      font-size: 14px;
      line-height: 20px;
      color: var(--dark-color);
      font-weight: 500;
      margin-bottom: 0;
      white-space: nowrap;
    }
    &:after {
      position: absolute;
      top: -7px;
      left: 50%;
      transform: translateX(-50%);
      right: inherit;
      text-align: center;
      display: inline-block !important;
      border-right: 7px solid transparent;
      border-bottom: 7px solid #f0f1f3;
      border-left: 7px solid transparent;
      content: "";
    }
    &:before {
      position: absolute;
      top: -8px;
      left: 50%;
      transform: translateX(-50%);
      right: inherit;
      display: inline-block !important;
      border-right: none;
      border-bottom: 8px solid #f0f1f3;
      border-left: none;
      content: "";
    }
    &.tool-right {
      left: inherit;
      transform: inherit;
      right: 0;
      &:after {
        left: inherit;
        transform: inherit;
        right: 10px;
      }
      &:before {
        left: inherit;
        transform: inherit;
        right: 10px;
      }
    }
  }
  &:hover {
    .tooltiptext {
      display: block;
    }
  }
  &.open {
    &:hover {
      .tooltiptext {
        display: none;
      }
    }
  }
}
.tab-group {
  position: relative;
  .current-design-btn {
    position: absolute;
    right: 23px;
    bottom: 7px;
    .dropdown-menu {
      display: none;
      border-radius: 10px !important;
      background-color: var(--color-white);
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25) !important;
      border: 1px solid var(--border-color);
      width: 250px;
      padding: 10px;
      top: 34px;
      left: auto;
      right: 0;
      position: absolute;
      z-index: 99;
      // overflow: hidden;
      .grid-layout-panel-listitem {
        width: 100%;
        margin-top: 10px;
        background: #f0f1f3;
        border-radius: 10px !important;
        padding: 4px;
        a {
          width: 100%;
          font-size: 14px !important;
          font-weight: 400 !important;
          color: var(--primary-color);
          border-radius: 10px !important;
          padding: 4px 8px;
          min-height: 34px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          svg {
            width: 15px;
          }
          &:hover {
            color: var(--primary-color);
            background-color: var(--hover-color) !important;
          }
        }
      }
    }
    &.open {
      .new-btn-2 {
        .tooltiptext {
          display: none !important;
        }
      }
      .dropdown-menu {
        display: block;
        &.dropdown-custom-width {
          max-width: 250px;
          min-width: auto;
          width: auto;
        }
      }
    }
    .new-btn-2 {
      // background-color: var(--light-gray) !important;
      // border: solid 1px var(--border-color) !important;

      background-color: var(--primary-color) !important;
      border: solid 1px var(--primary-color) !important;
      border-radius: 10px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 34px;
      min-width: 34px;
      position: relative;
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--color-white);
        i {
          font-size: 18px;
          color: var(--color-white);
        }
        svg {
          width: 18px;
          height: 18px;
          .innerColor {
            fill: var(--color-white);
          }
          #innerColorStro,
          .innerColorStro {
            stroke: var(--color-white);
          }
        }
      }
      &:before {
        left: 0 !important;
        width: 32px !important;
        background-color: var(--color-white);
        position: absolute;
        top: 0;
        left: 0 !important;
        display: block;
        height: 32px;
        content: "";
        transition: all 0.3s;
        transform: scale(0.5);
        opacity: 0;
        z-index: 1;
        border-radius: 10px !important;
      }
      .tooltiptext {
        display: none;
        text-align: left;
        padding: 0;
        position: absolute;
        z-index: 1;
        left: 50%;
        transform: translateX(-50%);
        right: inherit;
        top: 42px;
        min-width: auto;
        max-width: inherit;
        border-radius: 5px !important;
        background-color: var(--color-white);
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25) !important;
        border: 1px solid var(--border-color);
        z-index: 9999;
        p {
          margin: 0;
          padding: 5px 10px;
          text-align: left;
          font-size: 14px;
          line-height: 20px;
          color: var(--dark-color);
          font-weight: 500;
          margin-bottom: 0;
          white-space: nowrap;
        }
        &:after {
          position: absolute;
          top: -7px;
          left: 50%;
          transform: translateX(-50%);
          right: inherit;
          text-align: center;
          display: inline-block !important;
          border-right: 7px solid transparent;
          border-bottom: 7px solid #f0f1f3;
          border-left: 7px solid transparent;
          content: "";
        }
        &:before {
          position: absolute;
          top: -8px;
          left: 50%;
          transform: translateX(-50%);
          right: inherit;
          display: inline-block !important;
          border-right: none;
          border-bottom: 8px solid #f0f1f3;
          border-left: none;
          content: "";
        }
        &.tool-right {
          left: inherit;
          transform: inherit;
          right: 0;
          &:after {
            left: inherit;
            transform: inherit;
            right: 10px;
          }
          &:before {
            left: inherit;
            transform: inherit;
            right: 10px;
          }
        }
      }
      &:hover {
        border: solid 1px transparent !important;
        border-radius: 10px !important;
        &:before {
          opacity: 1;
          transform: scale(1);
          border-radius: 10px !important;
        }
        span {
          z-index: 9;
          color: var(--primary-color);
          i {
            color: var(--primary-color);
          }
          svg {
            .innerColor {
              fill: var(--primary-color) !important;
            }
            #innerColorStro,
            .innerColorStro {
              stroke: var(--primary-color);
            }
          }
        }
        .tooltiptext {
          display: block;
        }
      }
    }
    .upload-link {
      display: flex;
      font-size: 14px !important;
      font-weight: 400;
      margin: 0 0 5px 0 !important;
      color: var(--primary-color) !important;
      text-decoration: none;
      &.ml-10 {
        margin-left: 10px !important;
      }
      &:hover {
        text-decoration: underline;
      }
    }
    .upload-submit {
      color: var(--color-white);
      font-size: 14px;
      height: 34px;
      text-transform: capitalize;
      box-shadow: 0px 4px 6px 0px var(--brand-primary-rgb);
      -webkit-box-shadow: 0px 4px 6px 0px var(--brand-primary-rgb);
      margin-bottom: 0;
      margin-top: 0;
      width: 100%;
      border-radius: 10px !important;
      background-color: var(--primary-color);
      border: solid 1px var(--primary-color);
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      // overflow: hidden;
      position: relative;
      cursor: pointer;
      // &:hover {
      //   border: solid 1px var(--primary-color);
      //   background-color: var(--color-white);
      //   color:var(--primary-color);

      // }
      .uploadLoader {
        height: 12px;
        // width: 0.84vw;
      }
      svg {
        width: 15px;
        height: 15px;
      }
    }
  }
}
.current-design-btn {
  position: relative;
  .ml-10 {
    margin-left: 10px;
  }
  .dropdown-menu {
    display: none;
    border-radius: 0px !important;
    background-color: var(--color-white);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25) !important;
    border: 1px solid var(--border-color);
    width: 250px;
    padding: 10px;
    top: 34px;
    left: auto;
    right: 0;
    position: absolute;
    z-index: 99;
    // overflow: hidden;
  }
  &.open,
  .open {
    .new-btn-2 {
      .tooltiptext {
        display: none !important;
      }
    }
    .dropdown-menu {
      display: block;
    }
    .upload-submit {
      &:hover {
        .tooltiptext {
          display: block !important;
        }
      }
    }
  }
  .new-btn-2 {
    // background-color: var(--light-gray) !important;
    // border: solid 1px var(--border-color) !important;

    background-color: var(--primary-color) !important;
    border: solid 1px var(--primary-color) !important;
    border-radius: 10px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 34px;
    min-width: 34px;
    position: relative;
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--color-white);
      i {
        font-size: 18px;
        color: var(--color-white);
      }
      svg {
        width: 18px;
        height: 18px;
        .innerColor {
          fill: var(--color-white);
        }
        #innerColorStro,
        .innerColorStro {
          stroke: var(--color-white);
        }
        &.updown-arrow {
          width: 22px;
          height: 22px;
        }
      }
    }
    &:before {
      left: 0 !important;
      width: 32px !important;
      background-color: var(--color-white);
      position: absolute;
      top: 0;
      left: 0 !important;
      display: block;
      height: 32px;
      content: "";
      transition: all 0.3s;
      transform: scale(0.5);
      opacity: 0;
      z-index: 1;
      border-radius: 10px !important;
    }
    .tooltiptext {
      display: none;
      text-align: left;
      padding: 0;
      position: absolute;
      z-index: 1;
      left: 50%;
      transform: translateX(-50%);
      right: inherit;
      top: 42px;
      min-width: auto;
      max-width: inherit;
      border-radius: 5px !important;
      background-color: var(--color-white);
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25) !important;
      border: 1px solid var(--border-color);
      z-index: 9999;
      p {
        margin: 0;
        padding: 5px 10px;
        text-align: left;
        font-size: 14px;
        line-height: 20px;
        color: var(--dark-color);
        font-weight: 500;
        margin-bottom: 0;
        white-space: nowrap;
      }
      &:after {
        position: absolute;
        top: -7px;
        left: 50%;
        transform: translateX(-50%);
        right: inherit;
        text-align: center;
        display: inline-block !important;
        border-right: 7px solid transparent;
        border-bottom: 7px solid #f0f1f3;
        border-left: 7px solid transparent;
        content: "";
      }
      &:before {
        position: absolute;
        top: -8px;
        left: 50%;
        transform: translateX(-50%);
        right: inherit;
        display: inline-block !important;
        border-right: none;
        border-bottom: 8px solid #f0f1f3;
        border-left: none;
        content: "";
      }
      &.tool-right {
        left: inherit;
        transform: inherit;
        right: 0;
        &:after {
          left: inherit;
          transform: inherit;
          right: 10px;
        }
        &:before {
          left: inherit;
          transform: inherit;
          right: 10px;
        }
      }
    }
    &:hover {
      border: solid 1px transparent !important;
      border-radius: 10px !important;
      &:before {
        opacity: 1;
        transform: scale(1);
        border-radius: 10px !important;
      }
      span {
        z-index: 9;
        color: var(--primary-color);
        i {
          color: var(--primary-color);
        }
        svg {
          .innerColor {
            fill: var(--primary-color) !important;
          }
          #innerColorStro,
          .innerColorStro {
            stroke: var(--primary-color);
          }
        }
      }
      .tooltiptext {
        display: block;
      }
    }
  }
  .upload-link {
    display: flex;
    font-size: 14px !important;
    font-weight: 400;
    margin: 0 0 5px 0 !important;
    color: var(--primary-color) !important;
    text-decoration: none;
    &.ml-10 {
      margin-left: 10px !important;
    }
    &.mb-0 {
      margin-bottom: 0px !important;
    }
    &:hover {
      text-decoration: underline;
    }
  }
  .upload-submit {
    color: var(--color-white);
    font-size: 14px;
    height: 33px;
    text-transform: capitalize;
    box-shadow: 0px 4px 6px 0px var(--brand-primary-rgb);
    -webkit-box-shadow: 0px 4px 6px 0px var(--brand-primary-rgb);
    margin-bottom: 0;
    margin-top: 0;
    width: 100%;
    border-radius: 10px !important;
    background-color: var(--primary-color);
    border: solid 1px var(--primary-color);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    // overflow: hidden;
    position: relative;
    cursor: pointer;
    svg {
      width: 15px;
      height: 15px;
    }
    // &:hover {
    //   border: solid 1px var(--primary-color);
    //   background-color: var(--color-white);
    //   color:var(--primary-color);

    // }
    .uploadLoader {
      height: 12px;
      // width: 0.84vw;
    }
  }
}

.uploadBox {
  .formGroup {
    margin-bottom: 15px;
    overflow: visible;
    input {
      font-size: 15px !important;
      font-weight: 400 !important;
      height: 54px !important;
      color: var(--primary-color) !important;
      padding: 6px 12px;
      padding-top: 19px !important;
      padding-bottom: 3px !important;
      background-color: var(--light-gray);
      border-radius: 10px !important;
      border: 1px solid var(--brand-info);
      &.file-input {
        padding-top: 26px !important;
        padding-bottom: 0px !important;
        font-size: 14px !important;
      }
    }
    label {
      color: var(--label-color);
      font-size: 13px;
      transform: translate(0, 9px) scale(1);
      left: 13px !important;
      &.filled {
        transform: translate(0, 9px) scale(1);
      }
      .required {
        color: #e02222;
        font-size: 12px;
        padding-left: 2px;
      }
    }
  }
}

/* radio buttons */
.rContainer {
  font-size: 12px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  border-radius: 4px;
  padding: 8px;
}

.radio_container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: var(--tabBg);

  box-shadow: inset 0.5px 0.5px 2px 0 rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 4px;
}

.radioInput {
  appearance: none;
  display: none;
}

.label {
  /* font-family: "Open Sans", sans-serif; */
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: inherit;
  padding: 0 8px;
  height: 20px;
  text-align: center;
  /* border-radius: 9999px; */
  overflow: hidden;
  transition: linear 0.3s;
  color: #6e6e6edd;
  border-radius: 4px;
  cursor: pointer;
  @media (max-width: 1499px) {
    padding: 0 7px;
    font-size: 11px;
  }
}

input[type="radio"]:checked + .label {
  background-color: #ffffff;
  /* color: #f1f3f5; */
  font-weight: 600;
  transition: 0.3s;
}
/* radio buttons */

/* .item {white-space: nowrap;display:inline} */
/* min-width: 370px; */
/* @media (max-width: 400px) {
  min-width: 300px;
} */

/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type="number"] {
  -moz-appearance: textfield;
}

.react-datepicker-ignore-onclickoutside {
  width: 100% !important;
}

.react-datepicker-wrapper {
  border: none;
}

// other css
.btn {
  @apply bg-slate-800;
}
.w-46 {
  width: 46%;
}
.custome-modal {
  @apply p-5;
  border: 1px solid rgba(var(--borderColor), 0.69);
  border-radius: 20px;
  .modal-header {
    @apply flex justify-between items-center;
    border-bottom: 1px solid rgba(var(--borderColor), 0.69);
    padding-bottom: 20px;
    h2 {
      color: var(--primaryText);
      font-size: 20px;
      font-weight: 400;
      line-height: 1.3;
    }
  }
  .modal-body {
    padding-top: 20px;
    .allocates_ul {
      @apply flex justify-between items-center flex-wrap;
      li {
        @apply flex justify-start items-start flex-col flex-wrap mb-4;
        width: 48%;
        .text-title {
          @apply text-xs pb-1.5;
          color: var(--formLable);
          font-weight: 500;
        }
        .text-value {
          @apply text-base font-normal;
          color: var(--primaryText);
        }
      }
    }
    .form-main {
      @apply flex justify-between items-center;
      gap: 10px;
      &.fund-form,
      &.three-col {
        .form-group {
          width: 30%;
        }
      }
      &.order-wrap {
        flex-wrap: wrap;
      }
      .form-group {
        @apply flex justify-start items-start flex-col flex-wrap mb-3;
        width: 48%;
        .css-b62m3t-container {
          width: 100%;
        }
        .input-container {
          width: 100%;
        }
        label {
          // @apply text-xs pb-1.5;
          // color: var(--formLable);
          // font-weight: 500;

          padding-left: 10px;
          transform: translate(0, 5px) scale(1) !important;
          font-size: 10px;
          color: var(--label-color);
          font-weight: 400 !important;
          left: 0;
        }
        .form-control {
          @apply focus:outline-none h-full w-full text-base font-normal;
          border: 1px solid rgba(0, 0, 0, 0.2);
          border-radius: 10px;
          min-height: 45px;
          color: var(--primaryText);
          padding: 8px 12px;
        }
      }
      &.w-48 {
        width: 100%;
        .form-group {
          width: 48%;
        }
      }
      .allocates-button {
        @apply mb-4;
        cursor: pointer;
        width: 40px;
        display: flex;
        justify-content: center;
      }
      .extra-space {
        width: 39px;
      }
      .modal-green-btn {
        margin: 0 !important;
        &:hover {
          background-color: transparent;
          @apply border-green-50 text-green-50;
        }
      }
      .hidden {
        display: none !important;
      }
      &.new-form-main {
        .form-group {
          width: 13%;
          &:first-child {
            width: 35%;
          }
        }
        &:last-child {
          .allocates-button {
            margin-bottom: 0;
          }
        }
      }
      &.three-col {
        &:last-child {
          .allocates-button {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
// MEHUL WORK
.page-header {
  .page-header-top {
    .nav-wrap {
      .page-header-menu {
        .hor-menu {
          .navbar-nav {
            > li {
              > a {
                color: var(--menu-color);
              }
            }
          }
        }
      }
    }
  }
}
html {
  .navItem {
    color: var(--menu-color);
    &:hover {
      color: var(--menu-active-color);
    }
  }
}
.ms-options-wrap {
  .ms-options-wrap-name {
    span {
      font-size: 16px;
      max-width: 120px;
      text-align: right;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      @media (max-width: 1599px) {
        font-size: 14px !important;
      }
    }
  }
  > .ms-options {
    max-width: 350px;
    .ms-search {
      @apply relative;
      border: 1px solid rgba(91, 103, 119, 0.1803921569);
      input {
        border-radius: 0;
        width: calc(100% - 96px);
        padding: 0 3px;
        font-size: 15px;
        font-weight: 400;
        color: var(--primary-color);
        &::-webkit-input-placeholder {
          color: #787575;
          font-weight: 400;
        }

        &:-ms-input-placeholder {
          color: #787575;
          font-weight: 400;
        }

        &::placeholder {
          color: #787575;
          font-weight: 400;
        }
      }
    }
  }
}
table {
  .input-container {
    label {
      color: var(--formLable);
    }
  }
}
.table-head-wrap {
  th {
    img {
      padding-right: 1.5vh;
      margin-top: 0.8vh;
      width: 4vh;
    }
  }
}
.hoverTableCell {
  .list-bullet-style {
    margin-left: 0;
    margin-right: 8px;
    height: 0.78vw;
    width: 5px;
    display: block;
    border-radius: 10px;
  }
}
// .bg-layoutBg .bg-tableStripeBg {
//   background-color: var(--light-gray);
// }
#portfolio-selection {
  label {
    &:hover {
      &:nth-child(odd) {
        background-color: var(--medium-gray);
      }
    }
  }
}
.bg-layoutBg {
  .bg-tableStripeBg {
    &:hover {
      background-color: var(--hover-color) !important;
    }
  }
}
.hoverTableCell {
  &:hover {
    background-color: var(--hover-color) !important;
  }
}
.tableCell {
  &.pr-0 {
    &:first-child {
      padding-right: 0 !important;
    }
  }
}
.no-data-found {
  @apply w-full p-3.5;
  font-size: 14px;
}
.table-thead {
  @apply w-full;
}

.status-filter {
  > div {
    border-radius: 0 !important;
    > div,
    .css-lphf5g {
      flex-wrap: nowrap !important;
    }
  }
}
.order-tab-filter {
  > div {
    min-height: 34px;
    .css-xhqkue-ValueContainer {
      flex-wrap: nowrap !important;
    }
  }
}

.c-tooltip-wrap {
  position: relative;
  text-align: center;
}
.c-tooltip-wrap {
  .c-tooltip {
    display: none;
    background-color: #fff;
    box-shadow: 0 -2px 10px rgb(0 0 0 / 40%) !important;
    color: rgba(0, 0, 0, 0.5490196078);
    border-radius: 10px !important;
    overflow: visible !important;
    padding: 0 !important;
    min-width: fit-content !important;
    left: calc(50% - 5px);
    transform: translateX(-50%);
    position: absolute;
    top: 26px;
    z-index: 1;
    max-width: calc(100% - 16px) !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-transform: capitalize;
    &:after {
      content: "";
      position: absolute;
      left: calc(50% - 3px);
      top: -6px;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid #fff;
      transform: rotate(-180deg);
    }
    &:first-child {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    &:last-child {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    li {
      &:first-child {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
      &:last-child {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }
}
.TabContent {
  tr {
    &:nth-last-child(-n + 3) {
      .c-tooltip-wrap {
        .c-tooltip {
          top: auto;
          bottom: 26px;
          &:after {
            transform: rotate(0);
            top: auto;
            bottom: -6px;
          }
        }
      }
    }
    &:first-child {
      .c-tooltip-wrap {
        .c-tooltip {
          top: 26px;
          bottom: auto;
        }
      }
    }
  }
}
.c-tooltip-wrap {
  .c-tooltip-selected {
    font-size: 13px;
    line-height: 1;
    &:hover {
      .c-tooltip {
        display: block;
      }
    }
  }
  .c-tooltip {
    li {
      padding: 5px 10px;
      font-size: 11px;
      line-height: 14px;
    }
  }
}
table {
  &.sticky {
    &.bg-layoutBg {
      z-index: 2;
    }
  }
}
table {
  &.sticky {
    &.bg-layoutBg {
      + .infinite-scroll-component__outerdiv {
        z-index: 2;
      }
    }
  }
}
.c-tooltip-label {
  font-weight: bold;
  background-color: #ffba8a;
  color: #703105;
  border-radius: 4px;
  padding: 4px 8px;
  display: inline-block;
  min-width: 78px;
  // width: 100%;
  font-size: 11px;
}
.c-tooltip {
  .active {
    font-weight: bold;
  }
}
.Rejected {
  .c-tooltip-label {
    background-color: #ffba8a;
    color: #703105;
  }
  .c-tooltip {
    .active {
      background-color: #ffba8a;
      color: #703105;
    }
  }
}
.Submitted {
  .c-tooltip-label {
    background-color: #ffba8a;
    color: #f96c04;
  }
  .c-tooltip {
    .active {
      background-color: #ffba8a;
      color: #f96c04;
    }
  }
}
.Locked {
  .c-tooltip-label {
    background-color: #ffba8a;
    color: #f96c04;
  }
  .c-tooltip {
    .active {
      background-color: #ffba8a;
      color: #f96c04;
    }
  }
}
.Killed {
  .c-tooltip-label {
    background-color: #ecbdc2;
    color: #cf3341;
  }
  .c-tooltip {
    .active {
      background-color: #ecbdc2;
      color: #cf3341;
    }
  }
}
.Cancelled {
  .c-tooltip-label {
    background-color: #ecbdc2;
    color: #cf3341;
  }
  .c-tooltip {
    .active {
      background-color: #ecbdc2;
      color: #cf3341;
    }
  }
}
.Matched {
  .c-tooltip-label {
    background-color: #ffff00;
    color: #000;
  }
  .c-tooltip {
    .active {
      background-color: #ffff00;
      color: #000;
    }
  }
}
.Unmatched {
  .c-tooltip-label {
    background-color: #c9cc3f;
    color: #4a4c06;
  }
  .c-tooltip {
    .active {
      background-color: #c9cc3f;
      color: #4a4c06;
    }
  }
}
.Settled {
  .c-tooltip-label {
    background-color: #8aff8e;
    color: #36a16b;
  }
  .c-tooltip {
    .active {
      background-color: #8aff8e;
      color: #36a16b;
    }
  }
}
.Placed {
  .c-tooltip-label {
    background-color: #ffba8a;
    color: #f96c04;
  }
  .c-tooltip {
    .active {
      background-color: #ffba8a;
      color: #f96c04;
    }
  }
}
.Instructed {
  .c-tooltip-label {
    background-color: #cce6e7;
    color: #097175;
  }
  .c-tooltip {
    .active {
      background-color: #cce6e7;
      color: #097175;
    }
  }
}
.Filled {
  .c-tooltip-label {
    background-color: #8aff8e;
    color: #36a16b;
  }
  .c-tooltip {
    .active {
      background-color: #8aff8e;
      color: #36a16b;
    }
  }
}
.Partially {
  .c-tooltip-label {
    background-color: #ffff00 !important;
    color: #000 !important;
  }
  .c-tooltip {
    .active {
      background-color: #ffff00 !important;
      color: #000 !important;
    }
  }
}
.Working {
  .c-tooltip-label {
    background-color: #6daaff;
    color: #071932;
  }
  .c-tooltip {
    .active {
      background-color: #6daaff;
      color: #071932;
    }
  }
}
.Pending {
  .c-tooltip-label {
    background-color: #ff9b50;
    color: #7e3804;
  }
  .c-tooltip {
    .active {
      background-color: #ff9b50;
      color: #7e3804;
    }
  }
}

.show-tooltip {
  // display: flex;
  // flex-wrap: wrap;
  // position: absolute;
  // background-color: var(--tabBg);
  // color: #000;
  // padding: 0.5rem;
  // border-radius: 10px;
  // min-width: 75px;
  // max-width: 80%;
  // font-size: 12px;
  // left: 0%;
  // top: 100%;
  // z-index: 10;
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  // background-color: var(--tabBg);
  // color: #000;
  padding: 0.5rem;
  // border-radius: 10px;
  min-width: 75px;
  max-width: 80%;
  font-size: 12px;
  right: 1%;
  top: 100%;
  z-index: 10;
  background-color: #fff;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.4) !important;
  color: rgba(0, 0, 0, 0.5490196078);
  border-radius: 10px !important;
  overflow: visible !important;
  &:after {
    content: "";
    position: absolute;
    right: 15px;
    top: -6px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #fff;
    transform: rotate(-180deg);
  }
}
.hide-tooltip {
  display: none;
  position: absolute;
}

/* loader */
.loadingContainer {
  float: left;
  width: 100%;
  left: 0px;
  top: 50%;
  text-align: center;
  position: relative;
  padding: 0px;
  z-index: 9;
  margin-top: -60px;
}

.dual-ring {
  display: block;
  height: 30px !important;
  img {
    height: 100%;
  }

  &:after {
    content: " ";
    display: block;
    width: 120px;
    height: 120px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid #001932;
    border-color: #001932 transparent #001932 transparent;
    animation: dual-ring1 1.2s linear infinite;
    display: none;
  }

  img {
    position: absolute;
    top: 15px;
    left: 50%;
    margin-left: -46px;
  }
}
.loading {
  position: absolute;
  z-index: 99;
  display: inline-block;
  position: relative;
  width: 100%;
  height: 150px;
  bottom: 150px;
  background: rgb(var(--loading-rgb));
  background: linear-gradient(360deg, rgba(var(--loading-rgb), 1) 20%, rgba(var(--loading-rgb), 0) 100%);
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  &.full-page {
    height: 100%;
    background: var(--loading-bg) !important;
    display: block;
    position: absolute;
    height: 100%;
    top: 0;
    bottom: inherit;
    overflow: hidden;

    div {
      left: 44%;
      top: 50%;
      width: auto;
      height: auto;
      transform: translate(-44%, -50%);
    }
  }
  div {
    position: absolute;
    bottom: 0;
    left: 45%;
    transform: translateY(-45%);
    display: block;
    width: 100%;
    height: 20px;

    span {
      position: absolute;
      top: inherit;
      bottom: 10px;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background: var(--loader-primary);
      animation-timing-function: cubic-bezier(0, 1, 1, 0);

      &:nth-child(1) {
        left: 8px;
        animation: lds-ellipsis1 0.6s infinite;
      }

      &:nth-child(2) {
        left: 8px;
        animation: lds-ellipsis2 0.6s infinite;
      }

      &:nth-child(3) {
        left: 32px;
        animation: lds-ellipsis2 0.6s infinite;
      }

      &:nth-child(4) {
        left: 56px;
        animation: lds-ellipsis3 0.6s infinite;
      }

      @keyframes lds-ellipsis1 {
        0% {
          transform: scale(0);
        }

        100% {
          transform: scale(1);
        }
      }

      @keyframes lds-ellipsis3 {
        0% {
          transform: scale(1);
        }

        100% {
          transform: scale(0);
        }
      }

      @keyframes lds-ellipsis2 {
        0% {
          transform: translate(0, 0);
        }

        100% {
          transform: translate(24px, 0);
        }
      }
    }
  }
}
.leftCustom-50 {
  .loading {
    &.full-page {
      div {
        left: 47%;
        top: 50%;
        transform: translate(-47%, -50%);
      }
    }
  }
}
.input-container {
  .input-field {
    @apply w-full border border-borderColor focus:ring-0 animate-none focus:border-borderColor;
  }
}
@keyframes dual-ring1 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* order-form-group */
.main-body {
  max-height: calc(100vh - 70px);
  // margin: 4px;
  &.main-body {
    padding: 10px 16px 10px;
  }
  .main-box {
    background-color: var(--color-white);
    overflow: hidden;
    // height: calc((100vh - 86px) / 2);
  }
}
.order-form-group {
  padding: 0 0 0.6vw 0;
  @media (max-width: 991px) {
    padding: 0 0 12px 0;
  }
  .order-form-search {
    width: 100%;
    min-height: 2vw;
    padding: 0.5vw 0.5vw 0.5vw 2.26vw;
    font-size: 0.78vw;
    line-height: 1.25;
    border: 1px solid var(--borderColor2);
    color: var(--primaryText);
    border-radius: 10px;
    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
      background: url("../src/assets/close-icon.svg") no-repeat right center / 80%;
      width: 0.6vw;
      height: 0.6vw;
      @media (max-width: 991px) {
        width: 12px;
        height: 12px;
      }
    }
    &:focus {
      outline: none;
    }
    &::-webkit-input-placeholder {
      /* Edge */
      color: var(--orderFormPlaceholderColor);
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: var(--orderFormPlaceholderColor);
    }

    &::placeholder {
      color: var(--orderFormPlaceholderColor);
    }
    @media (max-width: 991px) {
      min-height: 40px;
      padding: 8px 8px 8px 30px;
    }
  }
  .order-search-list {
    background: var(--color-white);
    box-shadow: 0px 7px 16px rgba(var(--color-black-rgb), 0.29);
    border: 1px solid var(--borderColor2);
    padding: 0.42vw 0;
    border-radius: 10px;
    margin-top: 10px;
    width: 100%;
    z-index: 998;
    ul {
      max-height: 220px;
      overflow: auto;
      li {
        font-size: 0.75rem;
        line-height: 1.25;
        color: var(--orderFormPlaceholderColor);
        padding: 0.42vw 0.6vw;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        cursor: pointer;
        &:hover {
          background-color: var(--hover-color);
        }
        @media (max-width: 991px) {
          padding: 6px 12px;
        }
        .list-type {
          min-width: 5px;
          height: 0.78vw;
          border-radius: 10px;
          display: block;
          margin-right: 8px;
          &.equity-type {
            background-color: #50c076;
          }
          &.bond-type {
            background-color: #fce526;
          }
          &.fund-type {
            background-color: #50c0c0;
          }
          &.cash-type {
            background-color: #9caa99;
          }
          @media (max-width: 991px) {
            height: 12px;
          }
        }
      }
    }
    @media (max-width: 991px) {
      padding: 6px 0;
    }
  }
  .order-item-name {
    padding: 0.55vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--color-white);
    h3 {
      font-size: 0.78vw;
      line-height: 1.3;
      font-weight: 500;
      color: var(--secondaryText);
      @media (max-width: 991px) {
        font-size: 14px;
      }
    }
    .show-data-icon {
      width: 17px;
      cursor: pointer;
      &.close-icon {
        width: 12px;
        cursor: pointer;
      }
    }
  }
  .order-form-header {
    padding: 0.6vw 0.6vw 0;
    border-bottom: 1px solid var(--borderColor2);
    @media (max-width: 991px) {
      padding: 12px 12px 0;
    }
    .order-search {
      height: 0.84vw;
      width: 0.84vw;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 1vw;
    }
    .order-search-loader {
      height: 0.84vw;
      width: 0.84vw;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 1vw;
    }
  }
  .order-form-body {
    padding: 0.4vw 0.6vw;
    @media (max-width: 991px) {
      padding: 12px 12px;
    }
    .bid-ask-group {
      margin-bottom: 0.6vw;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      .bid-ask-box {
        width: 48%;
        border: 1px solid var(--borderColor2);
        border-radius: 10px;
        padding: 0.4vw 0.6vw;
        &.w-full {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        @media (max-width: 991px) {
          padding: 8px 16px;
        }
        h3 {
          font-size: 0.84vw;
          line-height: 1;
          text-transform: uppercase;
          font-weight: 600;
          display: flex;
          align-items: center;
          img {
            width: 0.52vw;
            @media (max-width: 991px) {
              width: 10px;
            }
            &.bid-icon {
              margin-left: 5px;
            }
            &.ask-icon {
              margin-right: 5px;
            }
          }
          @media (max-width: 991px) {
            font-size: 15px;
          }
        }
        span {
          font-size: 0.73vw;
          line-height: 1;
          color: var(--primaryText);
          text-transform: uppercase;
          font-weight: 600;
          margin-bottom: 5px;
          display: block;
          @media (max-width: 991px) {
            font-size: 14px;
          }
        }
        &.bid-box {
          h3 {
            color: var(--bidColor);
          }
        }
        &.ask-box {
          text-align: right;
          h3 {
            color: var(--askColor);
            justify-content: end;
          }
        }
      }
      .bid-ask-difference {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .bid-ask-difference {
        min-width: 3.7vw;
        height: 1.6vw;
        font-size: 0.84vw;
        line-height: 1;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--color-white);
        background-color: var(--primaryText);
        border-radius: 10px;
        padding: 0.25vw;
        @media (max-width: 991px) {
          font-size: 15px;
          min-width: 60px;
          height: 30px;
        }
      }
      @media (max-width: 991px) {
        margin-bottom: 12px;
      }
    }
    .order-form-main {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      flex-wrap: wrap;

      .order-form-select-input {
        width: 48%;
        margin-bottom: 0.6vw;
        &.w-full {
          width: 100%;
        }
        @media (max-width: 991px) {
          margin-bottom: 12px;
        }
      }
      .input-container {
        input {
          border: 1px solid var(--borderColor2);
          border-radius: 10px;
          padding: 1vw 0.6vw 0.4vw;
          min-height: 2.75vw;
          font-size: 0.73vw;
          line-height: 1.2;
          font-weight: 600;
          height: auto;

          @media (max-width: 991px) {
            font-size: 14px;
            padding: 12px 12px 6px;
          }
        }

        .filled {
          transform: translate(0, 6px) scale(0.9);
          left: 0.6vw;
          font-size: 0.63vw;
          line-height: 1.2;
          color: var(--formLable);
          font-weight: 500;
          height: auto;

          @media (max-width: 991px) {
            font-size: 14px;
            padding: 12px 12px 6px;
          }

          @media (max-width: 991px) {
            left: 0.6vw;

            @media (max-width: 991px) {
              left: 12px;
            }
          }
        }
      }
    }
  }
  .offer-wanted-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .offer-wanted-box {
      width: 48%;
      border: 1px solid var(--borderColor2);
      border-radius: 10px;
      padding: 0.4vw 0.6vw;
      @media (max-width: 991px) {
        padding: 8px 16px;
      }
      .ticker-price-text {
        font-size: 0.73vw;
        line-height: 1;
        color: var(--primaryText);
        font-weight: 600;
        margin-bottom: 5px;
        display: block;
        @media (max-width: 991px) {
          font-size: 14px;
        }
      }
      .ticker-price-value {
        font-size: 0.84vw;
        line-height: 1;
        font-weight: 600;
        color: var(--primaryText);
        &.buy-color {
          color: var(--bidColor);
        }
        &.sell-color {
          color: var(--askColor);
        }
        @media (max-width: 991px) {
          font-size: 15px;
        }
      }
    }
  }
  .offer-wanted-btn-group {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      width: 48%;
      font-size: 0.84vw;
      line-height: 1;
      font-weight: 600;
      color: var(--color-white);
      margin-top: 0.6vw;
      &.buy-color {
        background-color: var(--bidColor);
      }
      &.sell-color {
        background-color: var(--askColor);
      }
      @media (max-width: 991px) {
        font-size: 15px;
      }
      &.pass-btn {
        background-color: var(--primaryText);
      }
      &.req-btn {
        background-color: var(--primaryText);
      }
    }
  }
  .order-btn-group {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .button-box {
      width: 48%;
      position: relative;
      button {
        width: 100%;
      }
      .order-btton-loader-box {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0 auto;
        width: 100%;
        border-radius: 10px;
        padding: 0.4vw 0.6vw;
        min-height: 2.1vw;
        display: flex;
        justify-content: center;
        align-items: center;
        .order-search-loader {
          margin: 0 auto;
        }
      }
    }
  }
}
.custome-select {
  padding: 0;
  position: relative;
  .select-lable {
    transform: translate(0, 6px) scale(0.9);
    left: 0.5vw;
    font-size: 0.63vw;
    line-height: 1.2;
    color: var(--formLable);
    font-weight: 500;
    height: auto;
    position: absolute;
    top: 0;
    z-index: 9;
    @media (max-width: 991px) {
      font-size: 14px;
      padding: 12px 12px 6px;
    }

    @media (max-width: 991px) {
      left: 0.6vw;

      @media (max-width: 991px) {
        left: 12px;
      }
    }
  }
  &.client-select-input {
    .css-8myb9u-control {
      width: 300px;
    }
  }
}
.three-col {
  .custome-select {
    &.client-select-input {
      .css-8myb9u-control {
        width: 270px;
      }
    }
  }
}
.custom-btn {
  background-color: var(--primaryText);
  border: 1px solid var(--primaryText);
  border-radius: 10px;
  padding: 0.4vw 0.6vw;
  min-height: 2.1vw;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-white);
  width: 100%;
  cursor: pointer !important;
  &.ask-btn,
  &.red-btn {
    background-color: var(--askColor);
    border: 1px solid var(--askColor);
    + .order-btton-loader-box {
      background-color: var(--askColor);
      border: 1px solid var(--askColor);
    }
    &:hover {
      background-color: #a52107;
    }
    &.disabled,
    &:disabled {
      cursor: not-allowed !important;
      background-color: var(--askColor);
      opacity: 0.65 !important;
      &:hover {
        background-color: var(--askColor);
        opacity: 0.65 !important;
      }
    }
  }
  &.bid-btn,
  &.green-btn {
    background-color: var(--bidColor);
    border: 1px solid var(--bidColor);
    + .order-btton-loader-box {
      background-color: var(--bidColor);
      border: 1px solid var(--bidColor);
    }
    &:hover {
      background-color: #0b8800;
    }
    &.disabled,
    &:disabled {
      cursor: not-allowed !important;
      background-color: var(--bidColor);
      opacity: 0.65 !important;
      &:hover {
        background-color: var(--bidColor);
        opacity: 0.65 !important;
      }
    }
  }
  &.pass-btn {
    background-color: var(--primaryText);
    border: 1px solid var(--primaryText);
    + .order-btton-loader-box {
      background-color: var(--primaryText);
      border: 1px solid var(--primaryText);
    }
    &.disabled {
      cursor: not-allowed !important;
      background-color: var(--primaryText);
      opacity: 0.65 !important;
      &:hover {
        background-color: var(--primaryText);
        opacity: 0.65 !important;
      }
    }
    &:hover {
      background-color: #2f3950 !important;
      color: var(--color-white) !important;
      border-color: #2f3950 !important;
    }
  }
  &.req-btn {
    background-color: var(--primaryText) !important;
    border: 1px solid var(--primaryText) !important;
    + .order-btton-loader-box {
      background-color: var(--primaryText) !important;
      border: 1px solid var(--primaryText) !important;
    }
    &.disabled {
      cursor: not-allowed !important;
      background-color: var(--primaryText) !important;
      opacity: 0.65 !important;
      &:hover {
        background-color: var(--primaryText) !important;
        opacity: 0.65 !important;
      }
    }
    &:hover {
      background-color: #2f3950 !important;
      color: var(--color-white) !important;
      border-color: #2f3950 !important;
    }
  }
  &.hidden {
    display: none;
  }
  @media (max-width: 991px) {
    padding: 8px 16px;
    min-height: 35px;
  }
}

.ok-btn,
.cancel-btn {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
  border-radius: 0.375rem !important;
  min-height: 26px !important;
  font-size: 0.75rem !important;
  line-height: 1rem !important;
  white-space: nowrap !important;
  margin-right: 8px !important;
  font-weight: 400 !important  ;
  width: 100px !important;
  &:last-child {
    margin-right: 0;
  }
}
.ok-btn {
  background-color: #071932 !important;
  border: 1px solid #071932 !important;
  &:hover {
    background-color: rgba(176, 204, 243, 0.32) !important;
    color: #071932 !important;
    border-color: rgba(176, 204, 243, 0.32) !important;
  }
}
.cancel-btn {
  background-color: #a4a3a3 !important;
  border: 1px solid #a4a3a3 !important;

  &:hover {
    background-color: #444 !important;
    border-color: #444 !important;
  }
}
.transection-button-groups {
  display: flex;
  justify-content: flex-end;
  margin: 0 0 0 auto;
  .drawer-custom-btn {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-radius: 0.375rem;
    min-height: 1vw;
    font-size: 0.75rem;
    line-height: 1rem;
    white-space: nowrap;
    margin-right: 10px;
    font-weight: 400;
    &:last-child {
      margin-right: 0;
    }
  }
}
.custom-input {
  .input-container {
    input {
      border: 1px solid var(--borderColor2) !important;
      border-radius: 10px !important;
      padding: 1vw 0.6vw 0.4vw !important;
      min-height: 2.75vw !important;
      font-size: 0.73vw !important;
      line-height: 1.2 !important;
      font-weight: 600 !important;
      height: auto !important;

      @media (max-width: 991px) {
        font-size: 14px !important;
        padding: 12px 12px 6px !important;
      }
    }

    .filled {
      transform: translate(0, 6px) scale(0.9);
      left: 0.6vw;
      font-size: 0.63vw;
      line-height: 1.2;
      color: var(--formLable);
      font-weight: 500;
      height: auto;

      @media (max-width: 991px) {
        font-size: 14px;
        padding: 12px 12px 6px;
      }

      @media (max-width: 991px) {
        left: 0.6vw;

        @media (max-width: 991px) {
          left: 12px;
        }
      }
    }
  }
}
.notification-group {
  position: relative;
  .badge-counter {
    top: -2px;
    right: -2px;
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background-color: var(--askColor);
  }
}
.small-custom-btn {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 0.375rem;
  min-height: 1vw;
  font-size: 0.75rem;
  line-height: 1rem;
  white-space: nowrap;
  margin-right: 8px;
  font-weight: 400;
  &:last-child {
    // margin-right: 0;
  }
  &.kill-btn {
    // margin-right: 0;
    background-color: var(--askColor);
    border: 1px solid var(--askColor);
    color: var(--color-white);
    &:hover {
      background-color: #a52107;
    }
    &.disabled,
    &:disabled {
      cursor: not-allowed !important;
      background-color: var(--askColor);
      opacity: 0.65 !important;
      &:hover {
        background-color: var(--askColor);
        opacity: 0.65 !important;
      }
    }
  }
  &.filter-btn {
    background-color: var(--primaryText);
    border: 1px solid var(--primaryText);
    color: var(--color-white);
    margin: 0;
    &:hover {
      background-color: var(--hover-color);
      color: var(--primaryText);
      border-color: var(--hover-color);
    }
    &.disabled {
      cursor: not-allowed !important;
      background-color: var(--primaryText);
      opacity: 0.65 !important;
      &:hover {
        background-color: var(--primaryText);
        opacity: 0.65 !important;
      }
    }
  }
}
.scan-btn {
  background-color: var(--primaryText);
  border: 1px solid var(--primaryText);
  color: var(--color-white);
  margin: 0;
  &:hover {
    background-color: var(--hover-color);
    color: var(--primaryText);
    border-color: var(--hover-color);
  }
}
.drower-box {
  height: 100vh;
  .item-title {
    padding-left: 8px;
    padding-right: 8px;
  }
  .items-list {
    height: calc(100vh - 44px);
    overflow: auto;
    .item-name {
      display: flex;
      align-items: center;
      .item-name-value {
        padding: 8px;
      }
    }
  }
}
.text-base {
  @media (max-width: 1499px) {
    font-size: 0.9rem !important;
    line-height: 1.3rem !important;
  }
}
table {
  table-layout: fixed;
  tr {
    td {
      > div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: calc(100% - 16px);
      }
    }
  }
}
.table-title-name {
  padding-bottom: 8px;
}
.side-drawer-shadow {
  background-color: rgba(var(--color-black-rgb), 0.68);
  position: fixed;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  @apply delay-500;
}
.infinite-scroll-component__outerdiv {
  width: 100%;
  table-layout: initial;
}
.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.68);
}
.react-confirm-alert-body {
  padding: 20px;
  h1 {
    padding-bottom: 20px;
    margin-bottom: 20px;
    color: #071932 !important;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.3;
    border-bottom: 1px solid rgba(229, 229, 229, 0.69) !important;
  }
}
.react-confirm-alert-button-group {
  flex-direction: row-reverse;
  button {
    margin-right: 0;
    &:last-child {
      margin-right: 10px;
    }
  }
}
.order-search-loader {
  height: 10px;
  margin-right: 8px;
}
.Toastify__toast--success {
  color: #155724 !important;
  background-color: #d4edda !important;
  border-color: #d4edda !important;

  .Toastify__close-button {
    color: #155724 !important;
  }
}
.Toastify__toast--error {
  background-color: #f8d7da !important;
  border-color: #f8d7da !important;
  color: #721c24 !important;

  .Toastify__close-button {
    color: #721c24 !important;
  }
}
.menu-bg {
  background-color: var(--menu-bg) !important;
}
.primary-color-text {
  color: var(--primary-color);
}
tr {
  td {
    color: var(--tableTextColor);
    div {
      color: var(--tableTextColor);
    }
    p {
      color: var(--tableTextColor);
    }
  }
}
.text-sm {
  font-size: 0.75rem !important;
  line-height: 1rem;
}
.custom-checkbox {
  border-color: var(--checkbox-color);
}
.checkbox-color {
  background-color: var(--checkbox-color);
}
.highcharts-title {
  font-size: 0.8333vw !important;
  @media (max-width: 991px) {
    font-size: 13px !important;
  }
}
.client-area {
  margin-bottom: 16px;
  padding-right: 3px;
  .form-main {
    &:last-child {
      .form-group {
        margin-bottom: 0px;
      }
    }
  }
}
.stream-area {
  .buyBrokerBox {
    border: 1px solid var(--bidColor);
    background-color: rgba(var(--bidColorRGB), 0.1);
    padding: 6px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    border-radius: 10px;
    .brokerBox {
      width: 33%;
      span {
        padding-bottom: 0.375rem;
        font-size: 0.75rem;
        line-height: 1rem;
        color: var(--bidColor);
        font-weight: 500;
      }
      p {
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 500;
        color: var(--bidColor);
      }
    }
    .valueBox {
      width: 33%;
      span {
        padding-bottom: 0.375rem;
        font-size: 0.75rem;
        line-height: 1rem;
        color: var(--bidColor);
        font-weight: 500;
      }
      p {
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 500;
        color: var(--bidColor);
      }
    }
  }
  .sellBrokerBox {
    border: 1px solid var(--askColor);
    background-color: rgba(var(--askColorRGB), 0.1);
    padding: 6px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    border-radius: 10px;
    .brokerBox {
      width: 33%;
      span {
        padding-bottom: 0.375rem;
        font-size: 0.75rem;
        line-height: 1rem;
        color: var(--askColor);
        font-weight: 500;
      }
      p {
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 500;
        color: var(--askColor);
      }
    }
    .valueBox {
      width: 33%;
      span {
        padding-bottom: 0.375rem;
        font-size: 0.75rem;
        line-height: 1rem;
        color: var(--askColor);
        font-weight: 500;
      }
      p {
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 500;
        color: var(--askColor);
      }
    }
  }
}
.h-\[92vh\] {
  height: 92vh !important;
}
.minHeightCustom {
  max-height: 250px;
}
.user-details-logout {
  color: #ff0303 !important;
}
.customSpaceMaxi {
  padding: 10px 16px;
}
.bg-PrimaryBg {
  background-color: #f9fafb;
}

.new-header-right {
  .mb-10 {
    margin-bottom: 10px !important;
  }
  .upload-group {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 0 5px;
    &.flex-col {
      flex-direction: column;
      margin-bottom: 0 !important;
    }
    .file {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      > input[type="file"] {
        display: none !important;
      }

      label {
        cursor: pointer;
        outline: 0;
        background-color: #f0f1f3;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px !important;
        width: 34px;
        height: 34px;
        margin: 0 8px;
        svg {
          width: 18px;
          height: 18px;
          path {
            fill: #848485;
          }
        }
        .tooltiptext {
          display: none;
          text-align: left;
          padding: 0;
          position: absolute;
          z-index: 1;
          left: 50%;
          transform: translateX(-50%);
          right: inherit;
          top: 42px;
          min-width: auto;
          max-width: inherit;
          border-radius: 5px !important;
          background-color: var(--color-white);
          box-shadow: 0px 3px 6px rgba(var(--black-rgb), 0.25) !important;
          border: 1px solid var(--border-color);
          z-index: 999999;
          p {
            margin: 0;
            padding: 5px 10px;
            text-align: left;
            font-size: 14px;
            line-height: 20px;
            color: var(--dark-color);
            font-weight: 500;
            margin-bottom: 0;
            white-space: nowrap;
          }
          &:after {
            position: absolute;
            top: -7px;
            left: 50%;
            transform: translateX(-50%);
            right: inherit;
            text-align: center;
            display: inline-block !important;
            border-right: 7px solid transparent;
            border-bottom: 7px solid #f0f1f3;
            border-left: 7px solid transparent;
            content: "";
          }
          &:before {
            position: absolute;
            top: -8px;
            left: 50%;
            transform: translateX(-50%);
            right: inherit;
            display: inline-block !important;
            border-right: none;
            border-bottom: 8px solid #f0f1f3;
            border-left: none;
            content: "";
          }
          &.tool-right {
            left: inherit;
            transform: inherit;
            right: 0;
            &:after {
              left: inherit;
              transform: inherit;
              right: 10px;
            }
            &:before {
              left: inherit;
              transform: inherit;
              right: 10px;
            }
          }
        }
        &:hover {
          .tooltiptext {
            display: inline-table !important;
          }
        }
      }
      svg {
        &.uploadFileLbl {
          width: 26px;
          height: 26px;
          .innerColorSto,
          #innerColorSto {
            stroke: #848485;
          }
          .noFill {
            fill: none;
          }
        }
      }
    }
    .upload-submit {
      width: 34px;
      min-width: 34px;
      height: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      margin-top: 0;
      svg {
        width: 15px;
        height: 15px;
        path {
          fill: #ffffff;
        }
      }
      &:hover {
        border: solid 1px var(--primary-color);

        svg {
          path {
            fill: var(--primary-color);
          }
        }
      }
    }
    // .ffl-wrapper {
    //     width: 100%;
    //     .ffl-label {
    //         top: 9.5px;
    //         font-size: 14px;
    //     }
    //     .form-control {
    //         font-size: 14px !important;
    //         font-weight: 400 !important;
    //         height: 38px !important;
    //         color: var(--primary-color);
    //         padding-top: 15px !important;
    //     }
    //     select.form-control {
    //         padding-left: 8px !important;
    //     }
    //     &.ffl-floated {
    //         .ffl-label {
    //             top: 3px;
    //             font-size: 10px;
    //             padding-left: 13px;
    //         }
    //     }
    // }
    .input-container {
      input {
        padding-bottom: 3px !important;
        background-color: var(--light-gray);
        border-radius: 10px !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        height: 38px !important;
        color: var(--primary-color);
        padding-top: 15px !important;
      }
    }
  }
  .upload-file-name {
    font-size: 12px;
    font-weight: 400;
    color: #968a8a !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 10px);
    padding: 0 0 0 3px;
    margin: 0 0 5px;
    display: block;
  }
  .upload-link {
    margin-bottom: 10px;
    border: 1px solid transparent;
    min-height: 34px;
    padding: 4px 8px;
    font-size: 14px !important;
    font-weight: 400 !important;
    color: var(--primary-color);
    border-radius: 10px !important;
    margin: 0;
    display: flex;
    align-items: center;
    transition: all linear 0.4s;
    svg {
      margin-right: 10px;
      width: 18px;
      height: 18px;
      path {
        fill: var(--primary-color);
      }
    }
    &:hover {
      text-decoration: none;
      color: var(--primary-color) !important;
      background-color: var(--hover-color) !important;
      svg {
        path {
          fill: var(--primary-color);
        }
      }
    }
  }
  .download-link {
    margin-bottom: 0;
    border: 1px solid transparent;
    min-height: 34px;
    padding: 4px 8px;
    font-size: 14px !important;
    font-weight: 400 !important;
    color: var(--primary-color);
    border-radius: 10px !important;
    margin: 0;
    display: flex;
    align-items: center;
    transition: all linear 0.4s;
    cursor: pointer;
    svg {
      margin-right: 10px;
      width: 15px;
      height: 15px;
      path {
        fill: var(--primary-color);
      }
      #innerColorStro,
      .innerColorStro {
        stroke: var(--primary-color);
      }
    }
    &:hover {
      text-decoration: none;
      color: var(--primary-color) !important;
      background-color: var(--hover-color) !important;
      svg {
        path {
          fill: var(--primary-color);
        }
      }
    }
  }
  .logout-btn {
    margin-bottom: 0;
    border: 1px solid transparent;
    min-height: 34px;
    padding: 4px 8px;
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #ff0303;
    border-radius: 10px !important;
    margin: 0;
    display: flex;
    align-items: center;
    transition: all linear 0.4s;
    cursor: pointer;
    svg {
      margin-right: 10px;
      width: 18px;
      height: 18px;
      path {
        fill: #ff0303;
      }
    }
    &:hover {
      text-decoration: none;
      color: #ff0303 !important;
      background-color: rgba(255, 3, 3, 0.3) !important;
      svg {
        path {
          fill: #ff0303;
        }
      }
    }
  }
  .download-btn {
    &.download-link {
      width: 100%;
      background-color: transparent;
      span {
        display: flex;
        justify-content: start;
        align-items: center;
        width: 100%;
        .arrow-down {
          width: 13px;
          height: 8px;
          margin: 0 0 0 auto;
          path {
            fill: none !important;
            stroke: var(--primary-color);
          }
        }
      }
      &:hover {
        span {
          .arrow-down {
            path {
              fill: none !important;
              stroke: var(--primary-color);
            }
          }
        }
      }
    }
  }
  .main-download {
    &.open {
      .download-btn.download-link {
        color: var(--primary-color) !important;
        background-color: var(--hover-color) !important;
        border-radius: 10px 10px 0 0 !important;
        svg {
          path {
            fill: var(--primary-color);
          }
        }
        span {
          .arrow-down {
            path {
              fill: none !important;
              stroke: var(--primary-color);
            }
          }
        }
      }
    }
    .dropdown-menu {
      width: 100%;
      position: relative;
      border-radius: 0 0 10px 10px !important;
      box-shadow: none !important;
      overflow: hidden;
      top: 0;
      li {
        &:last-child {
          a,
          Link {
            border-radius: 0 0 10px 10px !important;
          }
        }
      }
    }
    .setting-dropdown-menu {
      margin-bottom: 10px;
    }
  }
  .dropdown-menu {
    border-radius: 10px !important;
  }
  .setting-dropdown-two {
    display: none;
  }
  .buttonGroup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 0 !important;
    > div {
      width: 48%;
      button {
        width: 100%;
      }
    }
  }
  .current-design-btn {
    &.ml-10 {
      margin-left: 10px !important;
    }
    .setting-dropdown-two {
      display: none;
      &.set-open {
        display: block;
      }
    }
    .setting-dropdown-one {
      &.set-open {
        display: block;
      }
    }
    &.openPortfolio,
    &.openNoti {
      .new-btn-2 {
        .tooltiptext {
          display: none;
        }
      }
    }
    &.open {
      .setting-dropdown-two {
        z-index: 99;
      }
    }
  }
}
.rounded-10px {
  border-radius: 10px !important;
}
.ms-options {
  box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.3607843137) !important;
  padding: 15px !important;
}

.main-download {
  .dropdown-menu {
    border-radius: 0px !important;
    background-color: var(--color-white);
    box-shadow: 0px 3px 6px rgba(var(--black-rgb), 0.25) !important;
    border: 1px solid var(--border-color);
    padding: 0;
    &:after,
    &:before {
      display: none !important;
    }
    &.tooltiptext {
      left: auto;
      right: 0;
      min-width: 100px;
    }
    > li {
      > a,
      Link {
        padding: 8px 16px;
        color: var(--primary-color) !important;
        text-decoration: none;
        display: block;
        clear: both;
        font-weight: 300;
        line-height: 18px;
        white-space: nowrap;
        font-size: 14px;
        &:hover,
        &:focus {
          border-radius: 0px !important;
          background-color: var(--dropdown-hover-color) !important;
          color: var(--primary-color) !important;
        }
      }
      &.active {
        a,
        Link {
          border-radius: 0px !important;
          background-color: var(--dropdown-hover-color) !important;
          color: var(--primary-color) !important;
        }
      }
    }
  }
}

.profil-setting-box {
  .form-main {
    .form-group {
      &.custom-input {
        .input-container {
          label {
            font-size: 14px;
            padding-left: 10px;
            &.filled {
              font-size: 10px;
              color: var(--label-color);
              font-weight: 400 !important;
              transform: translate(0, 5px) scale(1) !important;
              left: 0;
            }
            &.left-2 {
              left: 0;
            }
          }
          input {
            padding: 6px 12px;
            outline: none;
            box-shadow: none;
            transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
            width: 100%;
            font-size: 14px !important;
            font-weight: 400 !important;
            height: 38px !important;
            color: var(--primary-color);
            padding-top: 15px !important;
            padding-bottom: 3px !important;
            background-color: var(--light-gray);
            border-color: var(--light-gray) !important;
            min-height: auto !important;
            margin-bottom: 10px !important;
            border-radius: 10px !important;
          }
        }
      }
    }
  }
}
.custome-select {
  label {
    font-size: 14px;
    &.left-2 {
      left: 0;
    }

    &.select-lable {
      padding-left: 10px;
      transform: translate(0, 5px) scale(1) !important;
      font-size: 10px;
      color: var(--label-color);
      font-weight: 400 !important;
      left: 0;
    }
  }
  .form-control {
    padding: 6px 12px 6px 10px;
    outline: none;
    box-shadow: none;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    width: 100%;
    font-size: 14px !important;
    font-weight: 400 !important;
    height: 38px !important;
    color: var(--primary-color);
    padding-top: 15px !important;
    padding-bottom: 3px !important;
    background-color: var(--light-gray);
    border-color: var(--light-gray) !important;
    min-height: auto !important;
    margin-bottom: 10px !important;
    border-radius: 10px !important;
  }
}
.profile-setting-group-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 0 !important;
  .custom-btn {
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 15px;
    line-height: 1.42857;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 48%;
    color: var(--login-color) !important;
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    border-radius: 10px !important;
    min-height: 36px;
    &:hover,
    &:focus {
      color: var(--primary-color) !important;
      background-color: var(--login-color) !important;
      border-color: var(--primary-color) !important;
    }
  }
}
.required {
  color: #e02222;
  font-size: 12px;
  padding-left: 2px;
}
.p-relative {
  position: relative;
}
.tbl-daterange {
  padding: 5px 5px 6px 30px;
  position: relative;
  img {
    position: absolute;
    left: 10px;
    top: 8px;
    z-index: 9;
    width: 14px;
    height: 14px;
    padding: 0;
  }
  button {
    font-size: 14px;
    color: var(--primary-color);
  }
}
.daterangepicker-box {
  border-radius: 0px !important;
  background-color: var(--color-white);
  box-shadow: 0px 3px 6px rgba(var(--black-rgb), 0.25) !important;
  border: 1px solid var(--border-color-new);
  padding: 9px 14px;
  min-width: 190px;
  display: flex;
  flex-direction: column;
  flex-direction: column;
  position: absolute;
  z-index: 99;
  right: 0;
  &:after {
    position: absolute;
    top: -7px;
    right: 10px;
    display: inline-block !important;
    border-right: 7px solid transparent;
    border-bottom: 7px solid var(--primary-color);
    border-left: 7px solid transparent;
    content: "";
  }

  &:before {
    position: absolute;
    top: -8px;
    right: 9px;
    display: inline-block !important;
    border-right: none;
    border-bottom: 8px solid var(--primary-color);
    border-left: none;
    content: "";
  }
  .itemList {
    font-size: 13px;
    background: #f5f5f5;
    border: 1px solid #f5f5f5;
    color: var(--dark-color);
    padding: 3px 12px;
    margin-bottom: 8px;
    border-radius: 10px !important;
    cursor: pointer;
    input {
      display: none;
    }
    &:hover,
    &.active {
      background: var(--primary-color) !important;
      border: 1px solid var(--primary-color) !important;
      color: var(--color-white);
    }
  }
}
.switch-time-group {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.switch-chart-type {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--tabBg);
  box-shadow: inset 0.5px 0.5px 2px 0 rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 4px;
  span {
    padding: 0 5px;
    border-radius: 4px;
    svg {
      height: 20px;
      width: 20px;
    }
    &.icon-candlestick,
    &.icon-area {
      background-color: var(--color-white);
    }
  }
}
.highcharts-label-box {
  &.highcharts-tooltip-box {
    stroke: #071932;
  }
}

.boarding-coutout-svg {
  path {
    fill: rgba(0, 0, 0, 0.8) !important;
  }
}
#boarding-popover-item {
  background-color: #ffffff !important;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25) !important;
  border: 1px solid var(--border-color);
  border-radius: 10px !important;
  padding: 10px !important;
  min-width: 250px;
  max-width: 500px;
}
.boarding-popover-title {
  font-size: 16px;
  display: flex;
  border-radius: 0 !important;
  color: #071932;
  margin: 0 !important;
  font-weight: 700;
  padding: 0 !important;
  margin-bottom: 10px !important;
  line-height: 1 !important;
}
.boarding-popover-description {
  font-size: 14px;
  display: block;
  border-radius: 0 !important;
  color: #071932;
  margin: 0 !important;
  font-weight: 400;
  padding: 0 !important;
  margin-bottom: 10px !important;
  line-height: 1.3 !important;
  font-family: "Inter", sans-serif;
  br {
    margin-bottom: 5px;
  }
  .setp-item {
    margin-bottom: 8px;
  }
}
.boarding-popover-footer {
  display: flex !important;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  button {
    background: #ffffff !important;
    border: 1px solid #071932 !important;
    color: #071932;
    font-size: 14px;
    font-weight: 400;
    padding: 4px 8px !important;
    margin-bottom: 0 !important;
    transition: all 0.4s linear;
    border-radius: 10px;
    &:hover {
      border: 1px solid #071932 !important;
      background: #071932 !important;
      color: #ffffff;
    }
    &.boarding-close-btn {
      background: #ffffff !important;
      border: 1px solid #333 !important;
      color: #333 !important;
      &:hover {
        border: 1px solid #333 !important;
        background: #333 !important;
        color: #ffffff !important;
      }
    }
    &.boarding-disabled {
      cursor: no-drop;
      background: #ffffff !important;
      border: 1px solid #a4a3a3 !important;
      color: #a4a3a3 !important;
      &:hover {
        background: #ffffff !important;
        border: 1px solid #a4a3a3 !important;
        color: #a4a3a3 !important;
      }
    }
  }
  .boarding-btn-group {
    button {
      &:first-child {
        margin-right: 8px;
      }
    }
  }
}
.daterangepicker-tab-view {
  &.mr-8 {
    margin-right: 8px;
  }
  &.mt-8 {
    margin-top: 8px;
  }
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: var(--tabBg);
  box-shadow: inset 0.5px 0.5px 2px 0 rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 4px;
  li {
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: inherit;
    padding: 0 8px;
    height: 20px;
    text-align: center;
    /* border-radius: 9999px; */
    overflow: hidden;
    transition: linear 0.3s;
    color: rgba(110, 110, 110, 0.8666666667);
    border-radius: 4px;
    cursor: pointer;
    text-transform: capitalize;
    @media (max-width: 1499px) {
      padding: 0 7px;
      font-size: 11px;
    }
    &.active {
      background-color: #ffffff;
      font-weight: 600;
      transition: 0.3s;
    }
  }
}
.page-404 {
  background: transparent url("../src/assets/grey-bg.png") no-repeat top -70px right / cover;

  padding-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 70px);
  @media (max-width: 767px) and (min-width: 567px) {
    height: auto;
    padding: 50px 0;
  }
  .content-404 {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent url(../src/assets/map-bg.png) no-repeat top 80px center;
    background-size: 80% !important;
    @media (max-width: 767px) and (min-width: 567px) {
      background-image: none;
    }
  }
  .number {
    line-height: 1;
    margin-bottom: 15px;
    text-align: center;
    font-size: 100px;
    color: var(--primary-color);
    b {
      font-weight: 600;
    }
  }
  h3 {
    color: red;
    font-family: var(--brand-font);
    font-weight: 700;
    line-height: 1.2;
    margin: 0 0 15px;
    text-align: center;
    font-size: 24px;
  }
  p {
    color: var(--404-color);
    font-family: var(--brand-font);
    font-weight: 400;
    line-height: 1.4;
    margin: 0 0 15px;
    text-align: center;
    font-size: 15px;
  }
  @media (min-width: 1536px) {
    .container {
      max-width: 1170px;
    }
  }
  @media (min-width: 1280px) {
    .container {
      max-width: 1170px;
    }
  }
  .rturn-home {
    color: var(--color-white);
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    min-width: 160px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 20px;
    display: inline-block;
    border-radius: 10px !important;
    &:hover {
      color: var(--primary-color);
      text-decoration: none;
      background-color: var(--color-white);
    }
  }
}

#customLegend {
  border-radius: 4px;
  display: block;
  margin-top: 8px;
  position: absolute;
  top: 0;
  right: 25px;
  display: flex;
  background-color: var(--tabBg);
  box-shadow: inset 0.5px 0.5px 2px 0 rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 4px;
  gap: 5px;
  @media (max-width: 1499px) {
    right: 232px;
  }
  label {
    padding: 0 8px;
    height: 20px;
    margin: auto;
    font-size: 12px !important;
    color: rgba(110, 110, 110, 0.8666666667) !important;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.legendcheckbox {
  display: none;
}
.checking {
  font-weight: 600;
  color: #6daaff !important;
  padding: 2px;
  margin: 2px;
  background-color: #ffffff;
}
.highcharts-no-data {
  position: absolute;
  left: 0 !important;
  top: 0 !important;
  width: 100%;
  height: 100%;
  background: #ffffff;
  > span {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .no-datagroup {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      img {
        width: 9.375vw;
      }
      p {
        margin: 0;
        font-weight: 700 !important;
        font-size: 13px !important;
        color: var(--table-text);
      }
    }
  }
}
.no-data-found {
  position: relative;
  height: calc(100% - 77px);
  .no-data {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 13px !important;
    .no-datagroup {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      img {
        width: 9.375vw;
      }
      p {
        margin: 0;
        font-weight: 700 !important;
        font-size: 13px !important;
        color: var(--table-text);
      }
    }
  }
}
#react-confirm-alert {
  position: relative;
  z-index: 9999;
}
#warning-connectionBar {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 5px;
  text-align: center;
  background-color: #ffcc00;
  background-repeat: no-repeat;
  background-size: contain;
  transition: 1s ease;
  -webkit-transition: 1s ease;
  -moz-transition: 1s ease;
}
#connected-connectionBar {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 5px;
  text-align: center;
  background-color: #0b8800;
  background-repeat: no-repeat;
  background-size: contain;
  transition: 1s ease;
  -webkit-transition: 1s ease;
  -moz-transition: 1s ease;

  #connected-bar {
    color: #fff;
  }
}
/**grid layout*/
.grid-controll-btn {
}
.r-65 {
  right: 65px !important;
}
.grid-item {
  background: #f0f1f3;
  // border: 1px solid #141414;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
}
.grid-item__header {
  // width: 100%;
  display: flex;
  background-color: var(--tabBg);
  height: 42px;
  border-radius: 20px;
}
.grid-item__action {
  width: 15%;
  color: #fff;
  cursor: pointer;
  // margin: 10px 10px 0px 0px;
  display: flex;
  justify-content: end;
  align-items: center;

  .filter-btn {
    margin: 0px 5px 5px 0px !important ;
  }
  .kill-btn {
    // margin: 0px 5px 5px 0px !important ;
  }
}
.grid-item-header-title {
  float: left;
  text-align: left;
  color: var(--navItemsText);
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  /* color: #fff; */
  cursor: pointer;
  /* display: flex; */
  justify-content: end;
  align-items: center;
  margin: 8px 0px 0px 8px;
  width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  // text-align: left;
  // color: var(--navItemsText);
  // font-weight: 700;
  // font-size: 1rem;
  // line-height: 1.5rem;
  // // width: 27%;
  // /* color: #fff; */
  // cursor: pointer;
  // display: flex;
  // justify-content: end;
  // align-items: center;
  // margin: 0px 0px 0px 8px;
}
.grid-item__title {
  // font-size: 14px;
  //color: #fff;

  width: 40%;
  padding: 4px 0;
  text-align: left;

  color: var(--navItemsText);
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.grid-item__title:hover {
  cursor: move;
}
.grid-item__title .panel {
  box-shadow: none;
  border: none;
}
.grid-item__title .panel:focus {
  outline: none;
}
.grid-item__title .panel-heading {
  padding: 3px 0;
  background: #171819;
  border: none;
  color: rgba(255, 255, 255, 0.7);
}
.grid-item__title .panel-body {
  padding: 0 0 0 15px;
  background: #171819;
  color: rgba(255, 255, 255, 0.7);
}

.grid-item__graph {
  flex: 1;
  background-color: var(--color-white);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 0 0.6vw;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}

.type-item {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  padding: 3px 0;
}
.type-item:hover {
  color: #fff;
}

.type-item.selected {
  color: #fff;
}

.data-edit__error {
  color: rgba(255, 0, 0, 0.7);
  font-size: 12px;
  padding: 3px 0;
}

.recharts-text {
  font-size: 12px;
}

.detail__info {
  color: #fff;
  padding: 20px;
}

.detail__edit {
  padding: 20px;
}

.detail__info > div {
  padding: 5px 0;
}

label {
  color: #fff;
  display: block;
}
.dropdown > .btn-default,
.dropdown.open > .btn-default {
  background: #171819;
  border: 1px solid #141414;
  color: #fff;
}
.dropdown > .btn-default:hover,
.dropdown > .btn-default:focus .dropdown.open > .btn-default:hover,
.dropdown.open > .btn-default:focus {
  background: #171819;
  border: 1px solid #141414;
  color: #fff;
  outline: none;
}

.dropdown-menu {
  border: 1px solid #141414;
  background: #171819;
  color: #fff;
}
.dropdown-menu > li > a {
  color: rgba(255, 255, 255, 0.7);
}
.dropdown-menu > li > a:hover {
  background: #171819;
  color: #fff;
}
.react-resizable-handle {
  padding: 0 9px 9px 0 !important;
}
.react-grid-item > .react-resizable-handle::after {
  border-right-color: rgba(255, 255, 255, 0.5);
  border-bottom-color: rgba(255, 255, 255, 0.5);
}
.react-grid-item img {
  pointer-events: auto;
}
.modal-body {
  padding: 0;
}

.data-edit {
  position: relative;
}
.data-edit__update {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 10;
}

.detail-page__title,
.edit-page__title {
  position: fixed;
  top: 20px;
  left: 0;
  right: 0;
  color: #fff;
  text-align: center;
}

.go-back {
  position: fixed;
  right: 20px;
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  top: 20px;
  z-index: 10;
}

.go-back:hover {
  color: #fff;
  cursor: pointer;
}
.multi-option-tab {
  display: flex;
  position: relative;
  padding: 4px;
  font-size: 14px;
  background: #f0f1f3;
  border-radius: 10px !important;

  width: 100%;
  min-height: 34px;
  list-style: none;
  margin: 0 0 10px;
  align-items: center;
  justify-content: space-between;
  &:last-child {
    margin-bottom: 0;
  }
  li {
    width: 100%;
    text-align: center;
    padding: 0 2px;
    a {
      transition: all 0.2s;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      padding: 0.4em 0.9em;
      cursor: pointer;
      z-index: 2;
      width: 100%;
      text-align: center;
      text-decoration: none;
      white-space: nowrap;
      max-width: max-content;
      min-width: 100%;
      min-height: auto;
      color: var(--primary-color);

      &:hover,
      &:focus {
        transition: all 0.2s;
        background: transparent !important;
        border-radius: 10px !important;
        text-decoration: none;
        color: var(--primary-color) !important;
      }
      &.active {
        transition: all 0.2s;
        background: var(--hover-color) !important;
        border-radius: 10px !important;
        text-decoration: none;
        color: #2f8195 !important;
      }
    }
    &.active {
      a {
        transition: all 0.2s;
        background: var(--hover-color) !important;
        border-radius: 10px !important;
        text-decoration: none;
        color: #2f8195 !important;
      }
    }
  }
}
.user-main {
  .arrow-down {
    width: 13px !important;
    height: 8px !important;
    margin: 0 0 0 auto !important;
    path {
      fill: none !important;
      stroke: var(--primary-color);
    }
  }
}
.adminActions {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 100000;
  .adminButton {
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-color);
    background-color: #f0f8ff;
    border: 5px solid var(--primary-color);
    padding: 10px;
    cursor: pointer;
    border-radius: 100px !important;
    -webkit-box-shadow: 0 3px 12px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 3px 12px rgba(0, 0, 0, 0.1);
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    text-align: center;
    border-radius: 100%;
    z-index: 99;
    position: relative;
    .adminButtonClose {
      display: none;
    }
    &.open {
      > span {
        display: none;
      }
      .adminButtonClose {
        display: block;
        width: 30px;
      }
    }
    &:hover {
      text-decoration: none;
      -webkit-box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.14), 0 3px 8px 0 rgba(0, 0, 0, 0.11);
      -moz-box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.14), 0 3px 8px 0 rgba(0, 0, 0, 0.11);
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.14), 0 3px 8px 0 rgba(0, 0, 0, 0.11);
    }
  }
  .adminToggle {
    -webkit-appearance: none;
    position: absolute;
    border-radius: 50% !important;
    top: 0;
    left: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
    z-index: 2;
    transition: box-shadow 0.2s ease-in-out;
    box-shadow: 0 3px 5px 1px rgba(51, 51, 51, 0.3);

    &:hover {
      box-shadow: 0 5px 60px 2px rgba(51, 51, 51, 0.3) !important;
    }
  }
  .adminButtons {
    position: absolute;
    width: 100%;
    bottom: 120%;
    text-align: center;
    z-index: 99;
    a {
      display: flex;
      align-items: center;
      justify-content: end;
      font-size: 14px;
      font-weight: 500;
      text-decoration: none !important;
      margin: 10px auto 0;
      line-height: 1.15;
      color: var(--color-white);
      opacity: 0;
      visibility: hidden;
      position: relative;
      font-size: 14px;
      white-space: nowrap;
      margin-right: 5px;
      &.open {
        opacity: 1;
        visibility: visible;
        border-radius: 100%;
      }
      > span {
        width: 60px;
        min-width: 60px;
        height: 60px;
        border-radius: 50% !important;
        box-shadow: 0 0 5px 1px rgba(51, 51, 51, 0.3);
        background-color: var(--color-white);
        color: var(--primary-color);
        border: 0 solid var(--primary-color);
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        margin-bottom: 0;
      }
      &:hover {
        transform: scale(1.05);
      }
      &:focus {
        text-decoration: none !important;
      }
      &:nth-child(1) {
        transition: opacity 0.2s ease-in-out 0.3s, transform 0.15s ease-in-out;
      }
      &:nth-child(2) {
        transition: opacity 0.2s ease-in-out 0.25s, transform 0.15s ease-in-out;
      }
      &:nth-child(3) {
        transition: opacity 0.2s ease-in-out 0.2s, transform 0.15s ease-in-out;
      }
      &:nth-child(4) {
        transition: opacity 0.2s ease-in-out 0.15s, transform 0.15s ease-in-out;
      }
    }
  }
  .button-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(rgba(44, 42, 41, 0.8) 0%, #2c2a29 100%);
    opacity: 0;
    transition: opacity 0.15s linear;
    z-index: 9;
    visibility: hidden;
    &.open {
      opacity: 1;
      visibility: visible;
    }
  }
}
body .adminActions .adminButton:focus {
  text-decoration: none !important;
}
.custom-design-btn {
  // position: absolute;
  // right: 23px;
  // bottom: 7px;
  .dropdown-menu {
    // display: none;
    border-radius: 10px !important;
    background-color: var(--color-white);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25) !important;
    border: 1px solid var(--border-color);
    width: 250px;
    padding: 10px;
    top: 34px;
    left: auto;
    right: 0;
    position: absolute;
    z-index: 99;
    // overflow: hidden;
    .grid-layout-panel-listitem {
      width: 100%;

      a {
        width: 100%;
        font-size: 14px !important;
        font-weight: 400 !important;
        color: var(--primary-color);
        border-radius: 10px !important;
        padding: 4px 8px;
        min-height: 34px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        svg {
          width: 15px;
        }
        &:hover {
          color: var(--primary-color);
          background-color: var(--hover-color) !important;
        }
      }
    }
  }
  // &.open {
  //   .new-btn-2 {
  //     .tooltiptext {
  //       display: none !important;
  //     }
  //   }
  //   .dropdown-menu {
  //     display: block;
  //     &.dropdown-custom-width {
  //       max-width: 250px;
  //       min-width: auto;
  //       width: auto;
  //     }
  //   }
  // }
  .new-btn-2 {
    // background-color: var(--light-gray) !important;
    // border: solid 1px var(--border-color) !important;

    // background-color: var(--primary-color) !important;
    // border: solid 1px var(--primary-color) !important;
    // border-radius: 10px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 34px;
    // min-width: 34px;
    width: 22px;
    position: relative;
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--primary-color);
      i {
        font-size: 18px;
        color: var(--primary-color);
      }
      svg {
        width: 18px;
        height: 18px;
        .innerColor {
          fill: var(--primary-color);
        }
        #innerColorStro,
        .innerColorStro {
          stroke: var(--primary-color);
        }
      }
    }
    // &:before {
    //   left: 0 !important;
    //   width: 32px !important;
    //   background-color: var(--color-white);
    //   position: absolute;
    //   top: 0;
    //   left: 0 !important;
    //   display: block;
    //   height: 32px;
    //   content: "";
    //   transition: all 0.3s;
    //   transform: scale(0.5);
    //   opacity: 0;
    //   z-index: 1;
    //   border-radius: 10px !important;
    // }
    // .tooltiptext {
    //   display: none;
    //   text-align: left;
    //   padding: 0;
    //   position: absolute;
    //   z-index: 1;
    //   left: 50%;
    //   transform: translateX(-50%);
    //   right: inherit;
    //   top: 42px;
    //   min-width: auto;
    //   max-width: inherit;
    //   border-radius: 5px !important;
    //   background-color: var(--color-white);
    //   box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25) !important;
    //   border: 1px solid var(--border-color);
    //   z-index: 9999;
    //   p {
    //     margin: 0;
    //     padding: 5px 10px;
    //     text-align: left;
    //     font-size: 14px;
    //     line-height: 20px;
    //     color: var(--dark-color);
    //     font-weight: 500;
    //     margin-bottom: 0;
    //     white-space: nowrap;
    //   }
    //   &:after {
    //     position: absolute;
    //     top: -7px;
    //     left: 50%;
    //     transform: translateX(-50%);
    //     right: inherit;
    //     text-align: center;
    //     display: inline-block !important;
    //     border-right: 7px solid transparent;
    //     border-bottom: 7px solid #f0f1f3;
    //     border-left: 7px solid transparent;
    //     content: "";
    //   }
    //   &:before {
    //     position: absolute;
    //     top: -8px;
    //     left: 50%;
    //     transform: translateX(-50%);
    //     right: inherit;
    //     display: inline-block !important;
    //     border-right: none;
    //     border-bottom: 8px solid #f0f1f3;
    //     border-left: none;
    //     content: "";
    //   }
    //   &.tool-right {
    //     left: inherit;
    //     transform: inherit;
    //     right: 0;
    //     &:after {
    //       left: inherit;
    //       transform: inherit;
    //       right: 10px;
    //     }
    //     &:before {
    //       left: inherit;
    //       transform: inherit;
    //       right: 10px;
    //     }
    //   }
    // }
    // &:hover {
    //   border: solid 1px transparent !important;
    //   border-radius: 10px !important;
    //   &:before {
    //     opacity: 1;
    //     transform: scale(1);
    //     border-radius: 10px !important;
    //   }
    //   span {
    //     z-index: 9;
    //     color: var(--primary-color);
    //     i {
    //       color: var(--primary-color);
    //     }
    //     svg {
    //       .innerColor {
    //         fill: var(--primary-color) !important;
    //       }
    //       #innerColorStro,
    //       .innerColorStro {
    //         stroke: var(--primary-color);
    //       }
    //     }
    //   }
    //   .tooltiptext {
    //     display: block;
    //   }
    // }
  }
  .upload-link {
    display: flex;
    font-size: 14px !important;
    font-weight: 400;
    margin: 0 0 5px 0 !important;
    color: var(--primary-color) !important;
    text-decoration: none;
    &.ml-10 {
      margin-left: 10px !important;
    }
    &:hover {
      text-decoration: underline;
    }
  }
  .upload-submit {
    color: var(--color-white);
    font-size: 14px;
    height: 34px;
    text-transform: capitalize;
    box-shadow: 0px 4px 6px 0px var(--brand-primary-rgb);
    -webkit-box-shadow: 0px 4px 6px 0px var(--brand-primary-rgb);
    margin-bottom: 0;
    margin-top: 0;
    width: 100%;
    border-radius: 10px !important;
    background-color: var(--primary-color);
    border: solid 1px var(--primary-color);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    // overflow: hidden;
    position: relative;
    cursor: pointer;
    // &:hover {
    //   border: solid 1px var(--primary-color);
    //   background-color: var(--color-white);
    //   color:var(--primary-color);

    // }
    .uploadLoader {
      height: 12px;
      // width: 0.84vw;
    }
    svg {
      width: 15px;
      height: 15px;
    }
  }
}
.order-col-panel {
  .order-col-panel-header {
    padding: 10px;
    border-bottom: 1px solid var(--borderColor2);
  }
  .order-col-panel-body {
    padding: 10px;
    margin-top: 10px;
  }

  .order-col-panel-input {
    border: 1px solid var(--borderColor2);
    border-radius: 10px;
    padding: 1vw 0.6vw vw 0.4vw;
    min-height: 2.75vw;
    font-size: 0.73vw;
    line-height: 1.2;
    font-weight: 600;
    height: auto;
  }
  .order-col-panel-btn {
    margin-top: 20px;
    &:hover {
      border: solid 1px var(--primary-color);
      background-color: var(--color-white);
      color: var(--primary-color);
    }
  }
}
.watchlist-panel {
  .watchlist-panel-header {
    padding: 10px;
    border-bottom: 1px solid var(--borderColor2);
  }
  .watchlist-panel-body {
    padding: 10px;
    margin-top: 10px;
  }

  .watchlist-panel-input {
    border: 1px solid var(--borderColor2);
    border-radius: 10px;
    padding: 1vw 0.6vw vw 0.4vw;
    min-height: 2.75vw;
    font-size: 0.73vw;
    line-height: 1.2;
    font-weight: 600;
    height: auto;
  }
  .watchlist-panel-btn {
    margin-top: 20px;
    &:hover {
      border: solid 1px var(--primary-color);
      background-color: var(--color-white);
      color: var(--primary-color);
    }
  }
}
.action-btn {
  display: flex;
  > img {
    margin-left: 5px;
  }
}
/* Style the list to remove default list styles */
.checkbox-list {
  list-style: none;
  padding: 0;
}

/* Style the list items */
.checkbox-list li {
  margin: 5px 0;
  display: flex;
}

/* Style the checkboxes */
.checkbox-list input[type="checkbox"] {
  /* Add styles for the checkbox itself */
  margin-right: 5px;
  /* You can further customize the appearance of the checkbox */
}

/* Style the labels */
.checkbox-list label {
  /* Add styles for the label text */

  color: var(--navItemsText);
  width: 95%;
  /* You can add more styles like color, font size, etc. */
}
/* radio */
/* Style the list items */
.radio-list li {
  margin: 5px 0;
  display: flex;
  background-color: var(--tabBg);
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
}

/* Style the checkboxes */
.radio-list input[type="radio"] {
  /* Add styles for the radio itself */
  cursor: pointer;
  margin-right: 5px;
  /* You can further customize the appearance of the radio */
}

/* Style the labels */
.radio-list label {
  /* Add styles for the label text */

  cursor: pointer;

  color: var(--navItemsText);
  width: 95%;
  /* You can add more styles like color, font size, etc. */
}
.grid-sett-btn {
  margin: 0px -5px -3px 5px;
}
.grid-remove-btn {
  margin: 0.5rem;
}
.react-grid-item.react-grid-placeholder {
  background-color: var(--menu-bg);
  border-radius: 8px;
}
.watchlist-nodata {
  padding: 25px;
  width: 100%;
  display: block;
  text-align: center;
  color: var(--formLable);
}
.userwatchlist-panel {
  .userwatchlist-list {
    .userwatchlist-item-header {
      display: flex;
      color: var(--formLable);
      font-size: 0.75vw;
      // border-bottom: 1px solid var(--borderColor2);
      .lbl-name {
        width: 52%;
        padding: 5px;
        font-family: "Inter", sans-serif;
        box-sizing: border-box;
        padding-bottom: 8px;
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        color: var(--color-black);
        font-size: 0.75rem !important;
      }
      .lbl-bid {
        width: 24%;
        padding: 5px;
        font-family: "Inter", sans-serif;
        box-sizing: border-box;
        padding-bottom: 8px;
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        color: var(--color-black);
        font-size: 0.75rem !important;
      }
      .lbl-ask {
        width: 24%;
        padding: 5px;
        text-align: right;
        font-family: "Inter", sans-serif;
        box-sizing: border-box;
        padding-bottom: 8px;
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        color: var(--color-black);
        font-size: 0.75rem !important;
      }
    }
    .userwatchlist-item {
      display: flex;
      font-size: 0.65vw;
      position: relative;
      // border-bottom: 1px solid var(--borderColor2);
      .item-name {
        width: 52%;
        padding: 8px;
        display: grid;

        // white-space: nowrap;
        // overflow: hidden !important;
        // text-overflow: ellipsis;
        .i-name {
          display: inline-block;
          white-space: nowrap;
          overflow: hidden !important;
          text-overflow: ellipsis;
          color: var(--tableTextColor);
          font-size: 0.75rem;
        }
      }
      .item-bid {
        width: 24%;
        padding: 5px;
        display: grid;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
        .i-bid {
          color: var(--bidColor);
        }
      }
      .item-ask {
        width: 24%;
        text-align: right;
        padding: 5px;
        display: grid;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
        .i-ask {
          color: var(--askColor);
        }
      }
      .i-sub-txt {
        font-size: 0.45vw;
        color: var(--primaryText);
        font-weight: 500;
      }
      .item-btn {
        display: none !important;
        width: 100%;
        text-align: right;
        padding: 5px;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
        justify-content: end;
        svg {
          border-radius: 8px;
        }
        .item-del-btn {
          cursor: pointer;
          margin-left: 5px;
        }
        .item-buy-btn {
          cursor: pointer;
          margin-left: 5px;
        }
        .item-sell-btn {
          cursor: pointer;
          margin-left: 5px;
        }
      }
      &:hover {
        background-color: var(--hover-color);
      }
    }
    .userwatchlist-item:hover .item-btn {
      display: flex !important;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
    .userwatchlist-item:hover .item-bid {
      display: none;
    }
    .userwatchlist-item:hover .item-ask {
      display: none;
    }
  }
}
.watch-list-searchbar {
  height: 65%;
  width: 40%;

  .item-btn {
    padding: 0px !important;
  }
  .order-search-list {
    overflow: auto;
    width: 39.5%;
    overflow: scroll;
    max-height: calc(65% - 120px);
    overflow-x: overlay;
    padding-left: 10px;
    > ul {
      > li {
        display: flex;
        width: 99%;
        height: 32px;
        // border-bottom: 1px solid #e5e5e5;
        // margin-bottom: 5px;
        padding: 7px 0px 0px 7px;
        .addTowatch-list-add {
          cursor: pointer;
        }
      }
    }
  }
  .order-form-search {
    width: 97%;
    min-height: 2vw;
    padding: 0.5vw 0.5vw 0.5vw 1vw;
    font-size: 0.78vw;
    line-height: 1.25;
    border: 1px solid var(--borderColor2);
    color: var(--primaryText);
    border-radius: 10px;
    margin: 10px;
    &:focus {
      outline: none;
    }
  }
  .no-data-string {
    width: 100%;
    display: block;
    text-align: center;
  }
}
.order-col-list {
  height: 40%;
  width: 40%;
  .item-btn {
    padding: 0px !important;
  }
  .order-col-list {
    overflow: auto;
    width: 39%;
    // overflow: scroll;
    width: 39%;

    max-height: 50%;
    overflow-x: overlay;
    margin-left: 20px;
    > ul {
      display: grid;
      grid-template-columns: 50% 50%;
      vertical-align: middle;
      > li {
        display: flex;
        width: 99%;
        height: 32px;
        // border-bottom: 1px solid #e5e5e5;
        // margin-bottom: 5px;
        padding: 7px 0px 0px 7px;
        .addTowatch-list-add {
          cursor: pointer;
        }
      }
    }
  }
  .order-form-search {
    width: 97%;
    min-height: 2vw;
    padding: 0.5vw 0.5vw 0.5vw 1vw;
    font-size: 0.78vw;
    line-height: 1.25;
    border: 1px solid var(--borderColor2);
    color: var(--primaryText);
    border-radius: 10px;
    margin: 10px;
    &:focus {
      outline: none;
    }
  }
  .no-data-string {
    width: 100%;
    display: block;
    text-align: center;
  }
}
.round-plus {
  border-radius: 6px;
  border: 1px solid var(--primaryText);
  padding: 2px 0px 0px 2px;
}
.his-chart-nav {
  margin-top: 10px;
}
.switch-container {
  display: grid;
  // flex-direction: column;
  // align-items: center;
  margin: 0px 0px 12px 4px;
  grid-template-columns: 70% 30%;
  .opt-txt {
    color: var(--primary-color);
  }
}

.switch-label {
  position: relative;
  display: inline-block;
  cursor: pointer;
  font-size: 15px;
  p {
  }
}

.switch-input {
  display: none;
}

.switch-slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 34px;
  transition: 0.4s;
  height: 28px;
  width: 60px;
}

.switch-input:checked + .switch-slider {
  background-color: var(--menu-bg); /* Set the background color when the switch is ON */
  // background-color: #4caf50; /* Set the background color when the switch is ON */
}

.switch-slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 3px;
  // bottom: 4px;
  background-color: white;
  border-radius: 50%;
  transition: 0.4s;
  top: 1px;
}

.switch-input:checked + .switch-slider:before {
  transform: translateX(26px);
}
.fx-input-switch {
  position: absolute;
  top: 0;
  right: 0;
  /* margin-top: 1vw; */
  /* transform: translateY(-50%); */
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 0.6vw;
  background: var(--menu-bg) !important;
  border-radius: 0px 10px 10px 0px;
  font-size: 0.73vw;
  line-height: 1.2;
  font-weight: 600;
  color: var(--color-white) !important;
  cursor: pointer;
  width: 25%;
}

//transaction table tooltip issue related css
#OrdersBox table tr td > div {
  overflow: visible !important;
}

// orderstatusnew select styling
.custom-select > #react-select-41-listbox {
  font-size: 12px !important;
}

.label-filter {
  font-weight: 400 !important;
}

.target-span > span {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.show-button .buttonManage {
  opacity: 0;
  transition: opacity 0.5s ease;
}

.show-button:hover .buttonManage {
  opacity: 0.65;
}
.css-tlfecz-indicatorContainer,
.css-1gtu0rj-indicatorContainer {
  padding: 0 !important;
}
.mb-06vw {
  margin-bottom: 0.6vw;
}
.mt-03vw {
  margin-top: 0.3vw;
}
.order-confirmation-list {
  // display: grid;
  // grid-template-columns: repeat(4, 1fr);
  // gap: 5px;
  margin-bottom: 20px;
  .order-confirmation-list li:nth-child(1) {
    font-size: 13px;
    font-weight: 600;
  }
  .common-li {
    display: flex;
    flex-wrap: wrap;
    align-items: start;
    border-bottom: 1px solid rgba(var(--borderColor), 0.69);
    width: 95%;
  }

  .colspan-li {
    display: flex;
    flex-wrap: wrap;
    align-items: start;
    width: 95%;
    // justify-content: space-between;
  }
  /* CSS to set different widths for spans within li */
  .common-li span:nth-child(1) {
    width: 35%; /* Adjust the width as needed */
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .common-li span:nth-child(2) {
    width: 25%; /* Adjust the width as needed */
  }
  .common-li span:nth-child(3) {
    width: 20%; /* Adjust the width as needed */
  }
  .common-li span:nth-child(4) {
    width: 20%; /* Adjust the width as needed */
    text-align: right;
  }
  /* CSS to set different widths for spans within li */
  .colspan-li span:nth-child(1) {
    width: 60%; /* Adjust the width as needed */
    text-wrap: balance;
    text-align: left;
    position: sticky;
    top: 50%;
    transform: translateY(-50%);
    font-size: 13px;
  }
  .colspan-li span:nth-child(2) {
    width: 20%; /* Adjust the width as needed */
  }
  .colspan-li span:nth-child(3) {
    width: 20%; /* Adjust the width as needed */
  }

  /* CSS to set different widths for spans within li */
  .sub-title-list li:nth-child(1) {
    border-bottom: 1px solid rgba(var(--borderColor), 0.69);
  }
  .sub-title-list li:nth-child(2) {
    border-bottom: 1px solid rgba(var(--borderColor), 0.69);
  }
  .sub-title-list li:nth-child(3) {
    // border-bottom: 1px solid rgba(var(--borderColor), 0.69);
  }
  .sub-title-list li:nth-child(4) {
    border-bottom: 1px solid rgba(var(--borderColor), 0.69);
  }
  .sub-title-list li:nth-child(5) {
    border-bottom: 1px solid rgba(var(--borderColor), 0.69);
  }
  /* CSS to set different widths for spans within li */
  .sub-title-list-value li:nth-child(1) {
    border-bottom: 1px solid rgba(var(--borderColor), 0.69);
    text-align: right;
  }
  .sub-title-list-value li:nth-child(2) {
    border-bottom: 1px solid rgba(var(--borderColor), 0.69);
    text-align: right;
  }
  .sub-title-list-value li:nth-child(3) {
    // border-bottom: 1px solid rgba(var(--borderColor), 0.69);
    text-align: right;
  }
  .sub-title-list-value li:nth-child(4) {
    border-bottom: 1px solid rgba(var(--borderColor), 0.69);
    text-align: right;
  }
  .sub-title-list-value li:nth-child(5) {
    border-bottom: 1px solid rgba(var(--borderColor), 0.69);
    text-align: right;
  }
  .sub-title-list-value li:nth-child(6) {
    // border-bottom: 1px solid rgba(var(--borderColor), 0.69);
    text-align: right;
  }
  .font-class {
    // font-size: 13px;
    font-weight: 600;
  }
}

.order-confirmation-list li:nth-child(1) span:nth-child(1),
.order-confirmation-list li:nth-child(2) span:nth-child(1) {
  grid-column: span 2;
}

.blank-space {
  width: 71% !important;
}
.mt-10 {
  margin-top: 10px;
}
.trade-text {
  width: 100% !important;
  padding: 10px 0px 10px 0px;
  font-size: 12px;
}
.zIndex1Negative {
  z-index: -1 !important;
}

.heightTBodyNoData {
  height: calc(100% - 70px);
  height: -moz-calc(100% - 70px);
  height: -webkit-calc(100% - 70px);
}

.tbScroll {
  table {
    thead {
      th {
        border-right: 0 !important;
      }
    }
  }
}
.select-disabled {
  .css-1rpvzp1-IndicatorsContainer {
    display: none;
  }
  .css-1rpvzp1-IndicatorsContainer {
    display: none;
  }
  .css-1860oyy-indicatorContainer {
    display: none;
  }
  .css-3igypj-indicatorSeparator {
    display: none;
  }
  .css-1860oyy-indicatorContainer {
    display: none;
  }
  .css-1860oyy-indicatorContainer {
    display: none;
  }
}
.order-confirmation-table {
  margin-bottom: 20px;
  table {
    width: 100%;
    table-layout: auto;
    thead {
      tr {
        th {
          background-color: var(--tabBg);
          padding: 5px 8px;
          font-size: 12px;
          font-weight: 600;
          color: var(--color-black);
          text-align: left;
          &:first-child {
            border-radius: 12px 0 0 0;
          }
          &:last-child {
            border-radius: 0 12px 0 0;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 5px 8px;
          font-size: 13px;
          font-weight: 400;
          color: var(--tableTextColor);
          background-color: var(--color-white);
          text-align: left;
          position: relative;
          span {
            max-width: 220px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
            &:hover {
              + .tooltiptext {
                display: block;
              }
            }
          }
          .tooltiptext {
            display: none;
            text-align: left;
            padding: 0;
            position: absolute;
            z-index: 1;
            left: 0;
            right: inherit;
            top: 35px;
            min-width: auto;
            max-width: inherit;
            border-radius: 5px !important;
            background-color: var(--color-white);
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25) !important;
            border: 1px solid var(--border-color);
            z-index: 9999;
            overflow: visible;
            p {
              margin: 0;
              padding: 5px 10px;
              text-align: left;
              font-size: 14px;
              line-height: 20px;
              color: var(--dark-color);
              font-weight: 500;
              margin-bottom: 0;
              white-space: nowrap;
            }
            &:after {
              position: absolute;
              top: -7px;
              left: 10px;
              right: inherit;
              text-align: center;
              display: inline-block !important;
              border-right: 7px solid transparent;
              border-bottom: 7px solid var(--border-color);
              border-left: 7px solid transparent;
              content: "";
            }
            &:before {
              position: absolute;
              top: -8px;
              left: 10px;
              right: inherit;
              display: inline-block !important;
              border-right: none;
              border-bottom: 8px solid var(--border-color);
              border-left: none;
              content: "";
            }
            &.tool-right {
              left: inherit;
              transform: inherit;
              right: 0;
              &:after {
                left: inherit;
                transform: inherit;
                right: 5px;
              }
              &:before {
                left: inherit;
                transform: inherit;
                right: 5px;
              }
            }
          }
        }
        &.buy-row {
          td {
            background-color: rgba(var(--bidColorRGB), 0.3);
            // color: var(--bidColor);
          }
        }
        &.sell-row {
          td {
            background-color: rgba(var(--askColorRGB), 0.3);
            //  color: var(--askColor);
          }
        }
      }
    }
  }
  p {
    font-size: 13px;
    font-weight: 600;
    color: var(--tableTextColor);
    margin-top: 16px;
    padding-left: 8px;
  }
}
.new-security-add-title {
  font-size: 13px;
  font-weight: 600;
  color: var(--tableTextColor);
  margin-bottom: 4px;
  margin-top: 8px;
  padding-left: 12px;
}
.add-new-security-wrap {
  // display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  // padding: 8px 12px;
  .order-form-select-input {
    width: 100% !important;
    margin-bottom: 0 !important;
    &.mb-4 {
      margin-bottom: 16px !important;
    }
  }
  .custom-btn {
    width: 20% !important;
    min-height: 52.8px;
    &.w-full {
      width: 100% !important;
    }
    &:hover {
      background-color: transparent;
      color: var(--primary-color);
    }
  }

  .order-form-select-input {
    width: 48%;
    margin-bottom: 0.6vw;
    &.w-full {
      width: 100%;
    }
    @media (max-width: 991px) {
      margin-bottom: 12px;
    }
  }
  .input-container {
    textarea {
      border: 1px solid var(--borderColor2);
      border-radius: 10px;
      padding: 1vw 0.6vw 0.4vw;
      min-height: 2.75vw;
      font-size: 0.73vw;
      line-height: 1.2;
      font-weight: 600;
      height: auto;

      @media (max-width: 991px) {
        font-size: 14px;
        padding: 12px 12px 6px;
      }
    }
    input {
      border: 1px solid var(--borderColor2);
      border-radius: 10px;
      padding: 1vw 0.6vw 0.4vw;
      min-height: 2.75vw;
      font-size: 0.73vw;
      line-height: 1.2;
      font-weight: 600;
      height: auto;

      @media (max-width: 991px) {
        font-size: 14px;
        padding: 12px 12px 6px;
      }
    }
    label {
      transform: translate(0, 18px) scale(1);
      font-size: 14px;
      &.left-2 {
        left: 10px;
      }
    }
    &:focus-within {
      label {
        transform: translate(0, 5px) scale(1) !important;
        font-size: 10px;
        &.left-2 {
          left: 10px;
        }
      }
    }
    .filled {
      transform: translate(0, 6px) scale(0.9);
      left: 0.6vw;
      font-size: 0.63vw;
      line-height: 1.2;
      color: var(--formLable);
      font-weight: 500;
      height: auto;

      @media (max-width: 991px) {
        font-size: 14px;
        padding: 12px 12px 6px;
      }

      @media (max-width: 991px) {
        left: 0.6vw;

        @media (max-width: 991px) {
          left: 12px;
        }
      }
    }
  }
}
.custome-modal .form-main input[readonly="readonly"],
.custome-modal .form-main input:read-only,
.custome-modal input[readonly="readonly"],
.custome-modal input:read-only {
  // color: var(--formLable);
  background-color: var(--borderColor2);
  cursor: not-allowed;
}

body {
  .custom-btn.order-execute-btn {
    position: relative;
    opacity: 1 !important;
    // min-width: 115px;
    min-width: auto;
    padding: 0px 15px 0px 15px;
    &.disabled,
    &:disabled {
      opacity: 1 !important;
      background-color: rgba(var(--bidColorRGB), 0.65);
      border: 1px solid rgba(var(--bidColorRGB), 0.65) !important;
      &:hover {
        opacity: 1 !important;
        background-color: rgba(var(--bidColorRGB), 0.65);
        border: 1px solid rgba(var(--bidColorRGB), 0.65) !important;
      }
    }
    .tooltiptext {
      display: none;
      text-align: left;
      padding: 0;
      position: absolute;
      z-index: 1;
      left: 50%;
      transform: translateX(-50%);
      right: inherit;
      top: 42px;
      min-width: 23vw;
      max-width: 23vw;
      border-radius: 5px !important;
      background-color: var(--color-white);
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25) !important;
      border: 1px solid var(--border-color);
      z-index: 9999;
      p {
        margin: 0;
        padding: 5px 10px;
        text-align: left;
        font-size: 14px;
        line-height: 20px;
        color: var(--dark-color);
        font-weight: 500;
        margin-bottom: 0;
        white-space: normal;
      }
      &:after {
        position: absolute;
        top: -7px;
        left: 50%;
        transform: translateX(-50%);
        right: inherit;
        text-align: center;
        display: inline-block !important;
        border-right: 7px solid transparent;
        border-bottom: 7px solid #f0f1f3;
        border-left: 7px solid transparent;
        content: "";
      }
      &:before {
        position: absolute;
        top: -8px;
        left: 50%;
        transform: translateX(-50%);
        right: inherit;
        display: inline-block !important;
        border-right: none;
        border-bottom: 8px solid #f0f1f3;
        border-left: none;
        content: "";
      }
      &.tool-right {
        left: inherit;
        transform: inherit;
        right: 0;
        &:after {
          left: inherit;
          transform: inherit;
          right: 10px;
        }
        &:before {
          left: inherit;
          transform: inherit;
          right: 10px;
        }
      }
    }
    &:hover {
      .tooltiptext {
        top: 30px;
        opacity: 1 !important;
        display: block;
      }
    }
  }
}
.fx-quote {
  .fx-offer-wanted-wrap {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    gap: 10px;
    .fx-offer-wanted-box {
      width: 47%;
      border: 1px solid var(--borderColor2);
      border-radius: 10px;
      padding: 0.4vw 0.6vw;
      @media (max-width: 991px) {
        padding: 8px 16px;
      }
      .fx-ticker-price-text {
        font-size: 0.73vw;
        line-height: 1;
        color: var(--primaryText);
        font-weight: 600;
        margin-bottom: 5px;
        display: block;
        @media (max-width: 991px) {
          font-size: 14px;
        }
      }
      .ticker-price-value {
        font-size: 0.84vw;
        line-height: 1;
        font-weight: 600;
        color: var(--primaryText);
        &.buy-color {
          color: var(--bidColor);
        }
        &.sell-color {
          color: var(--askColor);
        }
        @media (max-width: 991px) {
          font-size: 15px;
        }
      }
    }
  }
}
.divider-allocation {
  border-bottom: 5px solid #fff;
  .td-text-indent {
    text-indent: 10px;
  }
}
.h-price-width {
  min-width: 75px;
  display: block;
}
.ml-0 {
  margin-left: 0px !important;
}
.ml-0-6rem {
  margin-left: 0.6rem !important;
}
.send-email-button-groups {
  display: flex;
  justify-content: flex-end;
  // margin: 15px;
}
.email-wrap {
  margin: 10px;
  .custom-input {
    margin: 0px 0px 15px 0px;
  }
  .tab-main {
    padding: 0px 0px 0;
    margin-bottom: 15px;
  }
}
.editor-container .ql-container {
  // border-radius: 10px;
  // border: 1px solid #ccc;
}

.editor-container .ql-editor {
  // border-radius: 10px;
  padding: 10px;
  min-height: 200px;
}
.editor-container .ql-toolbar {
  border-radius: 10px 10px 0px 0px;
}
.ql-container.ql-snow {
  border-radius: 0px 0px 10px 10px;
}
.trade-invalid-bg {
  background-color: #f3dddf;
}
#sett_date {
  border: 1px solid var(--borderColor2) !important;
  border-radius: 10px !important;
  /* padding: 1vw 0.6vw 0.4vw !important; */
  min-height: 2.75vw !important;
  font-size: 0.73vw !important;
  line-height: 1.2 !important;
  font-weight: 600 !important;
  height: auto !important;
}
.z-999 {
  z-index: 999 !important;
}
#trade_date {
  border: 1px solid var(--borderColor2) !important;
  border-radius: 10px !important;
  /* padding: 1vw 0.6vw 0.4vw !important; */
  min-height: 2.75vw !important;
  font-size: 0.73vw !important;
  line-height: 1.2 !important;
  font-weight: 600 !important;
  height: auto !important;
}
.zero_price_link {
  display: flex;
  margin: 10px;
  .as_link {
    cursor: pointer;
    display: flex;
    font-size: 16px !important;
    font-weight: 400;
    margin: 0 0 0px 5px !important;
    color: var(--link-color) !important;
    text-decoration: none;
    &.ml-10 {
      margin-left: 10px !important;
    }
    &:hover {
      text-decoration: underline;
    }
  }
  .with_disable {
    // color: gray !important;
  }
  &:hover {
    text-decoration: none !important;
  }
}
.topspace {
  top: -0.25px;
}

tr b{
  display: block;
}

.width-100{
  min-width: 80px !important;
}

.thead-color{
  background-color: var(--tabBg);
}