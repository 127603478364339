.theme {
  --dropdown-hover-color: #f2f2f2;
  --light-gray: #f7f7f7;
  --medium-gray: #f2f2f2;

  --menu-bg: #071932;
  --PrimaryBg: #f9fafb;
  --secondaryBg: #071932;
  --tabBg: #f0f1f3;
  --layoutBg: #ffffff;
  --tableStripe: #f0f0f0;
  --tableStripeHover: #b0ccf3;
  --navItemsText: #071932;
  --orderFormPlaceholderColor: #a4a3a3;
  /* UI work start */
  --bidColor: #3cbd31;
  --askColor: #e55235;
  --bidColorRGB: 60, 189, 49;
  --askColorRGB: 229, 82, 53;
  --borderColor: 229, 229, 229;
  --borderColor2: #e5e5e5;
  --selectHover: #f2f2f2;
  --formLable: #a4a3a3;
  --color-white: #ffffff;
  --color-black: #000000;
  --color-black-rgb: 0, 0, 0;
  --hover-color: rgba(176, 204, 243, 0.32);
  --loader-primary: #071932;
  --loading-rgb: 255, 255, 255;
  /* Dark Variables */
  --PrimaryBgDark: #f9fafb;
  --secondaryBgDark: #000000;
  --tabBgDark: #f0f1f3;
  --layoutBgDark: #ffffff;
  --tableStripeDark: #f7f7f7;
  --navItemsTextDark: #ffffff;
  --primaryText: #071932;
  --secondaryText: #234d88;

  // new var
  --menu-color: #8d8d8d;
  --menu-active-color: #fff;
  --primary-color: #071932;
  --tabItemText: #968a8a;
  --tableTextColor: #071932;
  --checkbox-color: #234d88;
  --arrowColor: #ffffff;
  --porfolio-text-color: #8d8d8d;
  --chart-color: #071932;
  --menu-font-color: #ffffff;
  --404-color: #8d8d8d;

  /* currency chart Colors */
  --CNH_Color: #508cc0;
  --CNH_HoverColor: #6197c6;

  --DKK_Color: #fcc326;
  --DKK_HoverColor: #fcc83b;

  --HKD_Color: #c06a50;
  --HKD_HoverColor: #c67861;

  --SEK_Color: #c050a6;
  --SEK_HoverColor: #c661ae;

  --SGD_Color: #6950c0;
  --SGD_HoverColor: #7761c6;

  --NOK_Color: #a650c0;
  --NOK_HoverColor: #ae61c6;

  --EUR_Color: #5081c0;
  --EUR_HoverColor: #618dc6;

  --CHF_Color: #c05050;
  --CHF_HoverColor: #c66161;

  --CAD_Color: #50c0b9;
  --CAD_HoverColor: #61c6bf;

  --LKR_Color: #50c08f;
  --LKR_HoverColor: #61c69a;

  --RUB_Color: #57c050;
  --RUB_HoverColor: #67c661;

  --USD_Color: #bcc050;
  --USD_HoverColor: #c2c661;

  --GBP_Color: #c0a250;
  --GBP_HoverColor: #c0a250;

  --ARS_Color: #c08850;
  --ARS_HoverColor: #c69361;

  --AUD_Color: #c06f50;
  --AUD_HoverColor: #c67d61;

  --JPY_Color: #8f50c0;
  --JPY_HoverColor: #9a61c6;

  --Defualt_Color: #c05084;
  --Default_HoverColor: #c66190;

  /* currency chart colors */
  --Equity_Color: #50c076;
  --Equity_HoverColor: #65c786;

  --Currency_Color: #9caa99;
  --Currency_HoverColor: #a8b4a5;

  --Bonds_Color: #fce526;
  --Bonds_HoverColor: #fce840;

  --Funds_Color: #50c0c0;
  --Funds_HoverColor: #61c6c6;

  /* cashflow chart color */
  --Coupen_Color: #ffc095;
  --Coupen_HoverColor: #ffdcc3;

  --Redemption_Color: #d08888;
  --Redemption_HoverColor: #fad7d7;

  --Trailer_Color: #a2ea9e;
  --Trailer_HoverColor: #d2fad0;

  /* performance chart color */
  --Realized_Color: #ffc095;
  --Realized_HoverColor: #ffdcc3;

  --Unrealized_Color: #d08888;
  --Unrealized_HoverColor: #fad7d7;

  --Trading_Color: #e9d248;
  --Trading_Hover: #f4e58b;

  --Interest_Color: #f78383;
  --Interest_HoverColor: #fcdede;

  /* holding chart/ trading chart area/historical chart */
  --areaGradient_Color: #e0e0e0;
  --areaGradientOne: rgba(224, 224, 224, 0);
  --areaLine_Color: #000000;

  // --bidGradient_Color: rgb(7, 25, 50);
  //   --bidGradientOne: rgba(7, 25, 50, 0);

  //   --askGradient_Color: #333333;
  //   --askGradientOne: rgba(51, 51, 51, 0);

  //   --midGradient_Color: rgb(117, 102, 96);
  //   --midGradientOne: rgba(117, 102, 96, 0);

  /* candlestick chart colors */
  --colorone: #e55235;
  --upColor: #3cbd31;
  --lineColor: #e55235;
  --upLineColor: #3cbd31;

  --redFillColor: #f8d7da;
  --greenFillColor: #d4edda;

  // New Class
  --tab-color: #333333;
  --hover-color: rgb(176, 204, 243, 0.32);
  --border-color: #e5e5e5;
  --dark-color: #333333;
  --brand-primary-rgb: rgba(0, 26, 52, 0.23);
  --label-color: #909090;
  --brand-info: #f4f4f4;
  --border-color-new: #e5e5e5;
  --black-rgb: 0, 0, 0;
  --border-color: #e5e5e5;
  --table-text: #071932;
  //link color
  --link-color: #0000ff;
  .logo {
    height: 52px;
    img {
      height: 100%;
    }
  }
  &.theme-arxfe {
    --light-gray: #f7f7f7;
    --medium-gray: #f2f2f2;
    --menu-bg: #ffffff;
    --menu-color: #000;
    --menu-active-color: #066d3f;
    --primary-color: #066d3f;
    --secondaryBg: #066d3f;
    --hover-color: rgba(6, 109, 63, 0.25);
    --tableStripeHover: rgba(6, 109, 63, 0.25);
    --tabItemText: #333333;
    --tableTextColor: #333333;
    --orderFormPlaceholderColor: #333333;
    --checkbox-color: #066d3f;
    --arrowColor: #066d3f;
    --loader-primary: #066d3f;
    --secondaryText: #066d3f;
    --primaryText: #066d3f;
    --porfolio-text-color: #333333;
    --chart-color: #066d3f;
    --menu-font-color: #066d3f;

    /* currency chart Colors */
    --CNH_Color: #508cc0;
    --CNH_HoverColor: #6197c6;

    --DKK_Color: #fcc326;
    --DKK_HoverColor: #fcc83b;

    --HKD_Color: #c06a50;
    --HKD_HoverColor: #c67861;

    --SEK_Color: #c050a6;
    --SEK_HoverColor: #c661ae;

    --SGD_Color: #6950c0;
    --SGD_HoverColor: #7761c6;

    --NOK_Color: #a650c0;
    --NOK_HoverColor: #ae61c6;

    --EUR_Color: #5081c0;
    --EUR_HoverColor: #618dc6;

    --CHF_Color: #c05050;
    --CHF_HoverColor: #c66161;

    --CAD_Color: #50c0b9;
    --CAD_HoverColor: #61c6bf;

    --LKR_Color: #50c08f;
    --LKR_HoverColor: #61c69a;

    --RUB_Color: #57c050;
    --RUB_HoverColor: #67c661;

    --USD_Color: #bcc050;
    --USD_HoverColor: #c2c661;

    --GBP_Color: #c0a250;
    --GBP_HoverColor: #c0a250;

    --ARS_Color: #c08850;
    --ARS_HoverColor: #c69361;

    --AUD_Color: #c06f50;
    --AUD_HoverColor: #c67d61;

    --JPY_Color: #8f50c0;
    --JPY_HoverColor: #9a61c6;

    --Defualt_Color: #c05084;
    --Default_HoverColor: #c66190;

    /* currency chart colors */
    --Equity_Color: #50c076;
    --Equity_HoverColor: #65c786;

    --Currency_Color: #9caa99;
    --Currency_HoverColor: #a8b4a5;

    --Bonds_Color: #fce526;
    --Bonds_HoverColor: #fce840;

    --Funds_Color: #50c0c0;
    --Funds_HoverColor: #61c6c6;

    /* cashflow chart color */
    --Coupen_Color: #ffc095;
    --Coupen_HoverColor: #ffdcc3;

    --Redemption_Color: #d08888;
    --Redemption_HoverColor: #fad7d7;

    --Trailer_Color: #a2ea9e;
    --Trailer_HoverColor: #d2fad0;

    /* performance chart color */
    --Realized_Color: #ffc095;
    --Realized_HoverColor: #ffdcc3;

    --Unrealized_Color: #d08888;
    --Unrealized_HoverColor: #fad7d7;

    --Trading_Color: #e9d248;
    --Trading_Hover: #f4e58b;

    --Interest_Color: #f78383;
    --Interest_HoverColor: #fcdede;

    /* holding chart/ trading chart area/historical chart */
    --areaGradient_Color: #e0e0e0;
    --areaGradientOne: rgba(224, 224, 224, 0);
    --areaLine_Color: #000000;

    /* candlestick chart colors */
    --colorone: #e55235;
    --upColor: #3cbd31;
    --lineColor: #e55235;
    --upLineColor: #3cbd31;

    --redFillColor: #f8d7da;
    --greenFillColor: #d4edda;
  }
  &.theme-hblbankuk {
    --light-gray: #f7f7f7;
    --medium-gray: #f2f2f2;
    --menu-bg: #ffffff;
    --menu-color: #5b5c5b;
    --menu-active-color: #008269;
    --primary-color: #008269;
    --secondaryBg: #008269;
    --hover-color: rgba(0, 130, 105, 0.25);
    --tableStripeHover: rgba(0, 130, 105, 0.25);
    --tabItemText: #576167;
    --tableTextColor: #576167;
    --orderFormPlaceholderColor: #576167;
    --checkbox-color: #008269;
    --arrowColor: #008269;
    --loader-primary: #008269;
    --secondaryText: #008269;
    --primaryText: #008269;
    --porfolio-text-color: #576167;
    --chart-color: #008269;
    --menu-font-color: #008269;

    /* currency chart Colors */
    --CNH_Color: #508cc0;
    --CNH_HoverColor: #6197c6;

    --DKK_Color: #fcc326;
    --DKK_HoverColor: #fcc83b;

    --HKD_Color: #c06a50;
    --HKD_HoverColor: #c67861;

    --SEK_Color: #c050a6;
    --SEK_HoverColor: #c661ae;

    --SGD_Color: #6950c0;
    --SGD_HoverColor: #7761c6;

    --NOK_Color: #a650c0;
    --NOK_HoverColor: #ae61c6;

    --EUR_Color: #5081c0;
    --EUR_HoverColor: #618dc6;

    --CHF_Color: #c05050;
    --CHF_HoverColor: #c66161;

    --CAD_Color: #50c0b9;
    --CAD_HoverColor: #61c6bf;

    --LKR_Color: #50c08f;
    --LKR_HoverColor: #61c69a;

    --RUB_Color: #57c050;
    --RUB_HoverColor: #67c661;

    --USD_Color: #bcc050;
    --USD_HoverColor: #c2c661;

    --GBP_Color: #c0a250;
    --GBP_HoverColor: #c0a250;

    --ARS_Color: #c08850;
    --ARS_HoverColor: #c69361;

    --AUD_Color: #c06f50;
    --AUD_HoverColor: #c67d61;

    --JPY_Color: #8f50c0;
    --JPY_HoverColor: #9a61c6;

    --Defualt_Color: #c05084;
    --Default_HoverColor: #c66190;

    /* currency chart colors */
    --Equity_Color: #50c076;
    --Equity_HoverColor: #65c786;

    --Currency_Color: #9caa99;
    --Currency_HoverColor: #a8b4a5;

    --Bonds_Color: #fce526;
    --Bonds_HoverColor: #fce840;

    --Funds_Color: #50c0c0;
    --Funds_HoverColor: #61c6c6;

    /* cashflow chart color */
    --Coupen_Color: #ffc095;
    --Coupen_HoverColor: #ffdcc3;

    --Redemption_Color: #d08888;
    --Redemption_HoverColor: #fad7d7;

    --Trailer_Color: #a2ea9e;
    --Trailer_HoverColor: #d2fad0;

    /* performance chart color */
    --Realized_Color: #ffc095;
    --Realized_HoverColor: #ffdcc3;

    --Unrealized_Color: #d08888;
    --Unrealized_HoverColor: #fad7d7;

    --Trading_Color: #e9d248;
    --Trading_Hover: #f4e58b;

    --Interest_Color: #f78383;
    --Interest_HoverColor: #fcdede;

    /* holding chart/ trading chart area/historical chart */
    --areaGradient_Color: #e0e0e0;
    --areaGradientOne: rgba(224, 224, 224, 0);
    --areaLine_Color: #000000;

    /* candlestick chart colors */
    --colorone: #e55235;
    --upColor: #3cbd31;
    --lineColor: #e55235;
    --upLineColor: #3cbd31;

    --redFillColor: #f8d7da;
    --greenFillColor: #d4edda;
    .logo {
      height: 30px;
    }
  }
  &.theme-gpp {
    --light-gray: #f7f7f7;
    --medium-gray: #f2f2f2;
    --menu-bg: #ffffff;
    --menu-color: #6d6e71;
    --menu-active-color: #006db2;
    --primary-color: #006db2;
    --secondaryBg: #006db2;
    --hover-color: rgba(0, 109, 178, 0.25);
    --tableStripeHover: rgba(0, 109, 178, 0.25);
    --tabItemText: #6d6e71;
    --tableTextColor: #6d6e71;
    --orderFormPlaceholderColor: #6d6e71;
    --checkbox-color: #006db2;
    --arrowColor: #006db2;
    --loader-primary: #006db2;
    --secondaryText: #006db2;
    --primaryText: #006db2;
    --porfolio-text-color: #6d6e71;
    --chart-color: #006db2;
    --menu-font-color: #006db2;

    /* currency chart Colors */
    --CNH_Color: #508cc0;
    --CNH_HoverColor: #6197c6;

    --DKK_Color: #fcc326;
    --DKK_HoverColor: #fcc83b;

    --HKD_Color: #c06a50;
    --HKD_HoverColor: #c67861;

    --SEK_Color: #c050a6;
    --SEK_HoverColor: #c661ae;

    --SGD_Color: #6950c0;
    --SGD_HoverColor: #7761c6;

    --NOK_Color: #a650c0;
    --NOK_HoverColor: #ae61c6;

    --EUR_Color: #5081c0;
    --EUR_HoverColor: #618dc6;

    --CHF_Color: #c05050;
    --CHF_HoverColor: #c66161;

    --CAD_Color: #50c0b9;
    --CAD_HoverColor: #61c6bf;

    --LKR_Color: #50c08f;
    --LKR_HoverColor: #61c69a;

    --RUB_Color: #57c050;
    --RUB_HoverColor: #67c661;

    --USD_Color: #bcc050;
    --USD_HoverColor: #c2c661;

    --GBP_Color: #c0a250;
    --GBP_HoverColor: #c0a250;

    --ARS_Color: #c08850;
    --ARS_HoverColor: #c69361;

    --AUD_Color: #c06f50;
    --AUD_HoverColor: #c67d61;

    --JPY_Color: #8f50c0;
    --JPY_HoverColor: #9a61c6;

    --Defualt_Color: #c05084;
    --Default_HoverColor: #c66190;

    /* currency chart colors */
    --Equity_Color: #50c076;
    --Equity_HoverColor: #65c786;

    --Currency_Color: #9caa99;
    --Currency_HoverColor: #a8b4a5;

    --Bonds_Color: #fce526;
    --Bonds_HoverColor: #fce840;

    --Funds_Color: #50c0c0;
    --Funds_HoverColor: #61c6c6;

    /* cashflow chart color */
    --Coupen_Color: #ffc095;
    --Coupen_HoverColor: #ffdcc3;

    --Redemption_Color: #d08888;
    --Redemption_HoverColor: #fad7d7;

    --Trailer_Color: #a2ea9e;
    --Trailer_HoverColor: #d2fad0;

    /* performance chart color */
    --Realized_Color: #ffc095;
    --Realized_HoverColor: #ffdcc3;

    --Unrealized_Color: #d08888;
    --Unrealized_HoverColor: #fad7d7;

    --Trading_Color: #e9d248;
    --Trading_Hover: #f4e58b;

    --Interest_Color: #f78383;
    --Interest_HoverColor: #fcdede;

    /* holding chart/ trading chart area/historical chart */
    --areaGradient_Color: #e0e0e0;
    --areaGradientOne: rgba(0, 109, 178, 0);
    --areaLine_Color: #006db2;

    /* candlestick chart colors */
    --colorone: #e55235;
    --upColor: #3cbd31;
    --lineColor: #e55235;
    --upLineColor: #3cbd31;

    --redFillColor: #f8d7da;
    --greenFillColor: #d4edda;
    .logo {
      height: 34px;
    }
  }

  &.theme-nobilis {
    --light-gray: #f7f7f7;
    --medium-gray: #f2f2f2;
    --menu-bg: #ffffff;
    --menu-color: #282828;
    --menu-active-color: #006f9e;
    --primary-color: #006f9e;
    --secondaryBg: #006f9e;
    --hover-color: rgba(0, 111, 158, 0.25);
    --tableStripeHover: rgba(0, 111, 158, 0.25);
    --tabItemText: #282828;
    --tableTextColor: #282828;
    --orderFormPlaceholderColor: #282828;
    --checkbox-color: #006f9e;
    --arrowColor: #006f9e;
    --loader-primary: #006f9e;
    --secondaryText: #006f9e;
    --primaryText: #006f9e;
    --porfolio-text-color: #282828;
    --chart-color: #006f9e;
    --menu-font-color: #006f9e;

    /* currency chart Colors */
    --CNH_Color: #508cc0;
    --CNH_HoverColor: #6197c6;

    --DKK_Color: #fcc326;
    --DKK_HoverColor: #fcc83b;

    --HKD_Color: #c06a50;
    --HKD_HoverColor: #c67861;

    --SEK_Color: #c050a6;
    --SEK_HoverColor: #c661ae;

    --SGD_Color: #6950c0;
    --SGD_HoverColor: #7761c6;

    --NOK_Color: #a650c0;
    --NOK_HoverColor: #ae61c6;

    --EUR_Color: #5081c0;
    --EUR_HoverColor: #618dc6;

    --CHF_Color: #c05050;
    --CHF_HoverColor: #c66161;

    --CAD_Color: #50c0b9;
    --CAD_HoverColor: #61c6bf;

    --LKR_Color: #50c08f;
    --LKR_HoverColor: #61c69a;

    --RUB_Color: #57c050;
    --RUB_HoverColor: #67c661;

    --USD_Color: #bcc050;
    --USD_HoverColor: #c2c661;

    --GBP_Color: #c0a250;
    --GBP_HoverColor: #c0a250;

    --ARS_Color: #c08850;
    --ARS_HoverColor: #c69361;

    --AUD_Color: #c06f50;
    --AUD_HoverColor: #c67d61;

    --JPY_Color: #8f50c0;
    --JPY_HoverColor: #9a61c6;

    --Defualt_Color: #c05084;
    --Default_HoverColor: #c66190;

    /* currency chart colors */
    --Equity_Color: #50c076;
    --Equity_HoverColor: #65c786;

    --Currency_Color: #9caa99;
    --Currency_HoverColor: #a8b4a5;

    --Bonds_Color: #fce526;
    --Bonds_HoverColor: #fce840;

    --Funds_Color: #50c0c0;
    --Funds_HoverColor: #61c6c6;

    /* cashflow chart color */
    --Coupen_Color: #ffc095;
    --Coupen_HoverColor: #ffdcc3;

    --Redemption_Color: #d08888;
    --Redemption_HoverColor: #fad7d7;

    --Trailer_Color: #a2ea9e;
    --Trailer_HoverColor: #d2fad0;

    /* performance chart color */
    --Realized_Color: #ffc095;
    --Realized_HoverColor: #ffdcc3;

    --Unrealized_Color: #d08888;
    --Unrealized_HoverColor: #fad7d7;

    --Trading_Color: #e9d248;
    --Trading_Hover: #f4e58b;

    --Interest_Color: #f78383;
    --Interest_HoverColor: #fcdede;

    /* holding chart/ trading chart area/historical chart */
    --areaGradient_Color: #e0e0e0;
    --areaGradientOne: rgba(0, 111, 158, 0);
    --areaLine_Color: #006f9e;

    /* candlestick chart colors */
    --colorone: #e55235;
    --upColor: #3cbd31;
    --lineColor: #e55235;
    --upLineColor: #3cbd31;

    --redFillColor: #f8d7da;
    --greenFillColor: #d4edda;
    .logo {
      height: 50px;
    }
  }
  &.theme-arwoly {
    --light-gray: #f7f7f7;
    --medium-gray: #f2f2f2;
    --menu-bg: #ffffff;
    --menu-color: #756660;
    --menu-active-color: #c87d2f;
    --primary-color: #c87d2f;
    --secondaryBg: #c87d2f;
    --hover-color: rgba(200, 125, 47, 0.25);
    --tableStripeHover: rgba(200, 125, 47, 0.25);
    --tabItemText: #756660;
    --tableTextColor: #756660;
    --orderFormPlaceholderColor: #756660;
    --checkbox-color: #c87d2f;
    --arrowColor: #c87d2f;
    --loader-primary: #c87d2f;
    --secondaryText: #c87d2f;
    --primaryText: #c87d2f;
    --porfolio-text-color: #756660;
    --chart-color: #c87d2f;
    --menu-font-color: #c87d2f;

    /* currency chart Colors */
    --CNH_Color: #508cc0;
    --CNH_HoverColor: #6197c6;

    --DKK_Color: #fcc326;
    --DKK_HoverColor: #fcc83b;

    --HKD_Color: #c06a50;
    --HKD_HoverColor: #c67861;

    --SEK_Color: #c050a6;
    --SEK_HoverColor: #c661ae;

    --SGD_Color: #6950c0;
    --SGD_HoverColor: #7761c6;

    --NOK_Color: #a650c0;
    --NOK_HoverColor: #ae61c6;

    --EUR_Color: #5081c0;
    --EUR_HoverColor: #618dc6;

    --CHF_Color: #c05050;
    --CHF_HoverColor: #c66161;

    --CAD_Color: #50c0b9;
    --CAD_HoverColor: #61c6bf;

    --LKR_Color: #50c08f;
    --LKR_HoverColor: #61c69a;

    --RUB_Color: #57c050;
    --RUB_HoverColor: #67c661;

    --USD_Color: #bcc050;
    --USD_HoverColor: #c2c661;

    --GBP_Color: #c0a250;
    --GBP_HoverColor: #c0a250;

    --ARS_Color: #c08850;
    --ARS_HoverColor: #c69361;

    --AUD_Color: #c06f50;
    --AUD_HoverColor: #c67d61;

    --JPY_Color: #8f50c0;
    --JPY_HoverColor: #9a61c6;

    --Defualt_Color: #c05084;
    --Default_HoverColor: #c66190;

    /* currency chart colors */
    --Equity_Color: #50c076;
    --Equity_HoverColor: #65c786;

    --Currency_Color: #9caa99;
    --Currency_HoverColor: #a8b4a5;

    --Bonds_Color: #fce526;
    --Bonds_HoverColor: #fce840;

    --Funds_Color: #50c0c0;
    --Funds_HoverColor: #61c6c6;

    /* cashflow chart color */
    --Coupen_Color: #ffc095;
    --Coupen_HoverColor: #ffdcc3;

    --Redemption_Color: #d08888;
    --Redemption_HoverColor: #fad7d7;

    --Trailer_Color: #a2ea9e;
    --Trailer_HoverColor: #d2fad0;

    /* performance chart color */
    --Realized_Color: #ffc095;
    --Realized_HoverColor: #ffdcc3;

    --Unrealized_Color: #d08888;
    --Unrealized_HoverColor: #fad7d7;

    --Trading_Color: #e9d248;
    --Trading_Hover: #f4e58b;

    --Interest_Color: #f78383;
    --Interest_HoverColor: #fcdede;

    /* holding chart/ trading chart area/historical chart */
    --areaGradient_Color: #e0e0e0;
    --areaGradientOne: rgba(200, 125, 47, 0);
    --areaLine_Color: #c87d2f;

    /* candlestick chart colors */
    --colorone: #e55235;
    --upColor: #3cbd31;
    --lineColor: #e55235;
    --upLineColor: #3cbd31;

    --redFillColor: #f8d7da;
    --greenFillColor: #d4edda;
    .logo {
      height: 50px;
    }
  }
  &.theme-latamconsultus {
    --light-gray: #f7f7f7;
    --medium-gray: #f2f2f2;
    --menu-bg: #ffffff;
    --menu-color: #303030;
    --menu-active-color: #0f2d4d;
    --primary-color: #0f2d4d;
    --secondaryBg: #0f2d4d;
    --hover-color: rgba(15, 45, 77, 0.25);
    --tableStripeHover: rgba(15, 45, 77, 0.25);
    --tabItemText: #303030;
    --tableTextColor: #303030;
    --orderFormPlaceholderColor: #303030;
    --checkbox-color: #0f2d4d;
    --arrowColor: #0f2d4d;
    --loader-primary: #0f2d4d;
    --secondaryText: #0f2d4d;
    --primaryText: #0f2d4d;
    --porfolio-text-color: #303030;
    --chart-color: #0f2d4d;
    --menu-font-color: #0f2d4d;

    /* currency chart Colors */
    --CNH_Color: #508cc0;
    --CNH_HoverColor: #6197c6;

    --DKK_Color: #fcc326;
    --DKK_HoverColor: #fcc83b;

    --HKD_Color: #c06a50;
    --HKD_HoverColor: #c67861;

    --SEK_Color: #c050a6;
    --SEK_HoverColor: #c661ae;

    --SGD_Color: #6950c0;
    --SGD_HoverColor: #7761c6;

    --NOK_Color: #a650c0;
    --NOK_HoverColor: #ae61c6;

    --EUR_Color: #5081c0;
    --EUR_HoverColor: #618dc6;

    --CHF_Color: #c05050;
    --CHF_HoverColor: #c66161;

    --CAD_Color: #50c0b9;
    --CAD_HoverColor: #61c6bf;

    --LKR_Color: #50c08f;
    --LKR_HoverColor: #61c69a;

    --RUB_Color: #57c050;
    --RUB_HoverColor: #67c661;

    --USD_Color: #bcc050;
    --USD_HoverColor: #c2c661;

    --GBP_Color: #c0a250;
    --GBP_HoverColor: #c0a250;

    --ARS_Color: #c08850;
    --ARS_HoverColor: #c69361;

    --AUD_Color: #c06f50;
    --AUD_HoverColor: #c67d61;

    --JPY_Color: #8f50c0;
    --JPY_HoverColor: #9a61c6;

    --Defualt_Color: #c05084;
    --Default_HoverColor: #c66190;

    /* currency chart colors */
    --Equity_Color: #50c076;
    --Equity_HoverColor: #65c786;

    --Currency_Color: #9caa99;
    --Currency_HoverColor: #a8b4a5;

    --Bonds_Color: #fce526;
    --Bonds_HoverColor: #fce840;

    --Funds_Color: #50c0c0;
    --Funds_HoverColor: #61c6c6;

    /* cashflow chart color */
    --Coupen_Color: #ffc095;
    --Coupen_HoverColor: #ffdcc3;

    --Redemption_Color: #d08888;
    --Redemption_HoverColor: #fad7d7;

    --Trailer_Color: #a2ea9e;
    --Trailer_HoverColor: #d2fad0;

    /* performance chart color */
    --Realized_Color: #ffc095;
    --Realized_HoverColor: #ffdcc3;

    --Unrealized_Color: #d08888;
    --Unrealized_HoverColor: #fad7d7;

    --Trading_Color: #e9d248;
    --Trading_Hover: #f4e58b;

    --Interest_Color: #f78383;
    --Interest_HoverColor: #fcdede;

    /* holding chart/ trading chart area/historical chart */
    --areaGradient_Color: #e0e0e0;
    --areaGradientOne: rgba(15, 45, 77, 0);
    --areaLine_Color: #0f2d4d;

    /* candlestick chart colors */
    --colorone: #e55235;
    --upColor: #3cbd31;
    --lineColor: #e55235;
    --upLineColor: #3cbd31;

    --redFillColor: #f8d7da;
    --greenFillColor: #d4edda;
    .logo {
      height: 50px;
    }
  }
}
